import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import lodash from 'lodash';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';

export interface ButtonAction {
    onClick: (item: any, isDisabled?: boolean) => void;
    icon?: string;
    isDisabled?: (item: any) => boolean;
    isVisible?: (item: any) => boolean;
    getStyle?: (item: any, isDisabled?: boolean) => any;
    getClass?: (item: any, isDisabled?: boolean) => any;
    tooltip?: string | ((item: any) => string);
    tooltipParams?: string;
    title?: string | ((item: any) => string);
    params?: any;
    permissions?: Array<string>;
    confirmActionBeforeClick?: (item: any, isDisabled?: boolean) => boolean;
    getConfirmActionText?: (item: any, isDisabled?: boolean) => string;
    dataTest?: string;
}

@Component({
    selector: 'finstein-datatable-buttons-column[row][buttons]',
    templateUrl: './buttons-column.component.html',
    styleUrls: ['./buttons-column.component.scss'],
})
export class ButtonsColumnComponent implements AfterViewInit {
    @Input()
    row: any;
    @Input()
    buttons: Array<ButtonAction> = [];
    @Input()
    forceDropdown = false;
    showDropdown = false;

    constructor(private dialog: MatDialog) {}

    ngAfterViewInit(): void {
        const onlyVisibleBtns = this.buttons.filter((button) =>
            this.isVisible(button)
        );
        if (this.forceDropdown || onlyVisibleBtns.length > 3) {
            this.showDropdown = true;
        }
    }

    isDisabled(customAction: ButtonAction) {
        if (customAction.isDisabled) {
            return customAction.isDisabled(this.row);
        }
        return false;
    }

    isVisible(customAction: ButtonAction) {
        if (customAction.isVisible) {
            return customAction.isVisible(this.row);
        }
        return true;
    }

    onClick(customAction: ButtonAction) {
        if (customAction.onClick) {
            if (
                customAction.confirmActionBeforeClick &&
                customAction.confirmActionBeforeClick(
                    this.row,
                    this.isDisabled(customAction)
                )
            ) {
                this.dialog
                    .open(ConfirmDialogComponent, {
                        panelClass: 'curved-modal',
                        data: {
                            text: customAction.getConfirmActionText(
                                this.row,
                                this.isDisabled(customAction)
                            ),
                        },
                    })
                    .afterClosed()
                    .subscribe((confirmed) => {
                        if (confirmed) {
                            customAction.onClick(
                                this.row,
                                this.isDisabled(customAction)
                            );
                        }
                    });
            } else {
                customAction.onClick(this.row, this.isDisabled(customAction));
            }
        }
    }

    getStyle(customAction: ButtonAction) {
        if (customAction.getStyle) {
            return customAction.getStyle(
                this.row,
                this.isDisabled(customAction)
            );
        }
    }

    getClass(customAction: ButtonAction) {
        if (customAction.getClass) {
            return customAction.getClass(
                this.row,
                this.isDisabled(customAction)
            );
        }
    }

    /**
     * Method responsible for mapping the items in loop button.
     *
     * @param index - button position;
     * @param item - item ( any )
     *
     * @returns unique identifier;
     */
    trackButton(index, item) {
        return `${index}-button`;
    }

    getTitle(button: any) {
        return lodash.isFunction(button.title)
            ? button.title(this.row)
            : button.title;
    }

    getTooltip(button: any) {
        return lodash.isFunction(button.tooltip)
            ? button.tooltip(this.row)
            : button.tooltip;
    }

    checkToShowDropdown() {
        return this.buttons.filter((button) =>
            this.isVisible(button)
        ).length > 3
    }
}
