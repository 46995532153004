import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions } from '@angular/fire/functions';
import { Title } from '@angular/platform-browser';
import lodash from 'lodash';
import { Observable, of } from 'rxjs';
import { map, share, switchMap, tap } from 'rxjs/operators';
import { UserModel } from 'src/app/shared/models/user.model';
import {
    COMPANIES_COLLECTION,
    OP_EQUALS,
    STATUS_ACTIVE,
    STATUS_DONE,
    STATUS_TODO,
    TASKS_COLLECTION,
    USERS_COLLECTION
} from '../../../app.constants';
import { BaseService } from '../../../core/abstractions/base-service';
import { FireLoggingService } from '../../../core/services/fire-logging.service';
import { SoundService } from '../../../core/services/sound.service';
import { UserService } from '../../../core/services/user.service';
import { CompaniesService } from '../../../modules/administration/company-leads/companies.service';
import { FilterModel } from '../../../shared/models/filter.model';

const LABEL_ORDER = {
    PROCESS: 1,
    CONFIRM: 2,
    INFORM: 3,
};

@Injectable({
    providedIn: 'root',
})
export class CockpitService extends BaseService<any> {

    private qtyTasksSnapshot = 0;

    constructor(
        private userService: UserService,
        private soundService: SoundService,
        private titleService: Title,
        private companiesService: CompaniesService,
        protected firestore: AngularFirestore,
        protected http: HttpClient,
        protected functions: Functions,
        protected fireLogging: FireLoggingService
    ) {
        super(firestore, http, functions, fireLogging);
    }

    getAllOpenTasks() {
        const filterValue = this.buildFilter(
            STATUS_TODO
        );
        return this.getCompanyResponsible()
        .pipe(
            switchMap((companyResponsible) => {
                if (!this.userService.isFinsteinHelper()) {
                    filterValue.clauses.push({
                        fieldPath: 'userId',
                        opStr: OP_EQUALS,
                        value: companyResponsible ? companyResponsible.id : this.userService.loggedUser.id,
                    });
                }
                return this.search(
                    `${COMPANIES_COLLECTION}/${this.userService.companyId}/${TASKS_COLLECTION}`,
                    filterValue
                ).pipe(
                    map((openTasks) => {
                        return Array.isArray(openTasks) ? openTasks : []
                            .sort((taskA, taskB) => LABEL_ORDER[taskA.label] - LABEL_ORDER[taskB.label])
                            .filter((task) => {
                                let mustShowTheTask = true;
                                if (task.title) {
                                    const isTaskNewAssign = task?.title?.endsWith('-new-assign');
                                    const isSelfAssigned = task?.assignedBy === this.userService?.loggedUser?.id;
                                    let isNotRequiredTask = [task.label, task.action].includes('INFORM');
                                    const hasActionOrLabelAsProcess = [task.label, task.action].includes('PROCESS');
                                    // If the hierarchy is STAND_IN and this task has action or label equals to PROCESS, we won't show it.
                                    if (task.hierarchy === 'STAND_IN' && hasActionOrLabelAsProcess) {
                                        isNotRequiredTask = true;
                                    }
                                    // According to the requirements, a task self-signed that is not mandatory (with label or action INFORM)
                                    // to fill something in the Portal, don't need to show in the cockpit.
                                    mustShowTheTask = !(isTaskNewAssign && isSelfAssigned && isNotRequiredTask);
                                }
                                if (this.userService.isFinsteinHelper()) {
                                    return !lodash.isNil(task.userId);
                                }
                                return !lodash.isNil(task.userId) && mustShowTheTask;
                            });
                    }),
                    share()
                );
            })
        );
    }

    get qtyTasksToDo() {
        return this.getAllOpenTasks().pipe(
            map((openTasks) => openTasks.length),
            tap((qtyTasksToDo) => {
                this.updatePageTitle(qtyTasksToDo);
                if (qtyTasksToDo > this.qtyTasksSnapshot) {
                    this.soundService.playNotification();
                }
                this.qtyTasksSnapshot = qtyTasksToDo;
            })
        );
    }

    /**
     * Fetches all permission-enabled users, in other words who are not views users;
     *
     * @returns Users - Observable;
     */
    getAllUsersByCurrentCompany() {
        const filter = new FilterModel();
        filter.sort = 'desc';
        filter.column = 'createdAt';
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_EQUALS,
                value: STATUS_ACTIVE,
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        return this.search(USERS_COLLECTION, filter).pipe(
            map((data: Array<UserModel>) => {
                return data.filter((value: any) => !value?.readOnly);
            }),
            share()
        );
    }

    closeTask(taskId: string) {
        return new Observable((observer) => {
            this.firestore
                .collection(
                    `${COMPANIES_COLLECTION}/${this.userService.companyId}/${TASKS_COLLECTION}`
                )
                .doc(taskId)
                .update({
                    viewed: true,
                    status: STATUS_DONE,
                })
                .then(() => observer.next())
                .catch(error => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${COMPANIES_COLLECTION}/${this.userService.companyId}/${TASKS_COLLECTION}/${taskId}`;
                    this.fireLogging.sendErrorLog(`[closeTask(cockpit.service.ts)] An error occurred while updating item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    observer.error(error);
                });
        });
    }

    buildFilter(status: string): FilterModel {
        const filter = new FilterModel();
        filter.sort = 'desc';
        filter.column = 'createdAt';
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_EQUALS,
                value: status,
            }
        ];
        return filter;
    }

    protected buildFirebaseFilter(ref, filter: FilterModel) {
        ref = this.buidlFirebaseOrderFilter(ref, filter);
        if (filter.clauses) {
            filter.clauses.forEach((clause) => {
                ref = ref.where(clause.fieldPath, clause.opStr, clause.value);
            });
        }
        return ref;
    }

    private updatePageTitle(qtyTasksToDo: number) {
        const oldTitle = this.titleService.getTitle().split('(')[0];
        const suffix = qtyTasksToDo > 0 ? `(${qtyTasksToDo})` : ``;
        this.titleService.setTitle(`${oldTitle} ${suffix}`.trim());
    }

    private getCompanyResponsible(): Observable<any> {
        if (this.userService.isFinsteinHelper()) {
            return this.companiesService.getCompanyResponsible(this.userService.companyId);
        }
        return of(false);
    }
}
