import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import lodash from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import { CompaniesService } from 'src/app/modules/administration/company-leads/companies.service';
import { ButtonAction } from 'src/app/shared/components/datatable-utils/components/buttons-column/buttons-column.component';
import { AccountingSystemsEnum } from 'src/app/shared/enums/accounting-systems.enum';
import {
    COLLECTIVE_AGREEMENT_COLLECTION,
    COMPANIES_COLLECTION,
    DEADLINE_FOR_BOOKING_SUGGESTIONS_COLLECTION,
    DIFFERENT_MINIMUM_WAGE_COLLECTION,
    EMPLOYEES_COLLECTION,
    OP_EQUALS,
    OP_IN,
    OP_NOT_EQUALS,
    QUESTIONS_COLLECTION,
    REMOVE_OPTIN_FUNCTION,
    STATUS_ACTIVE, STATUS_CONSULTATION_APPROVAL_PENDING,
    STATUS_CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS,
    STATUS_DATA_EXPORT_PENDING,
    STATUS_DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS,
    STATUS_INACTIVE,
} from '../../../../../app.constants';
import { BaseListComponent } from '../../../../../core/abstractions/base-list.component';
import { EmployeeStatusEnum } from '../../../../../shared/enums/employee-status.enum';
import { FilterModel } from '../../../../../shared/models/filter.model';
import { BookkeepingKeyService } from '../../../bookkeeping-suggestions/bookkeeping-keys/services/bookkeeping-key.service';
import { ChooseImportationFileComponent } from '../../choose-importation-file/choose-importation-file.component';
import { OptinsService } from '../../optins.service';
import { RequiredSettingsComponent } from '../../required-settings/required-settings.component';
import { WAGE_ACCOUNTING_SYSTEM_COLLECTION } from './../../../../../app.constants';
import { NotEligibleListModalComponent } from '../../not-eligible-list-modal/not-eligible-list-modal.component';

const SYSTEMS_BLOCKED_FOR_IMPORT = [
    AccountingSystemsEnum.SAP.toLocaleLowerCase(),
];

interface RequiredSettings {
    questions: boolean;
    address: boolean;
    deadlineForBookingSuggestions: boolean;
    wageAccountingSystem: boolean;
    collectiveAgreement: boolean;
    differentMinimumWage: boolean;
    otherServices: boolean;
    services: boolean;
    wageTypesTaxable: boolean;
    wageTypesTaxFree: boolean;
}

@Component({
    selector: 'app-optins-list-approved',
    templateUrl: './optins-list-approved.component.html',
    styleUrls: ['./optins-list-approved.component.scss'],
})
export class OptinsListApprovedComponent
    extends BaseListComponent
    implements OnInit, OnDestroy
{
    @ViewChild('optinApprovedList') table: DatatableComponent;

    @Input() isViewMode = false;

    buttons: Array<ButtonAction> = [
        {
            icon: 'dangerous',
            onClick: (item) => this.openNotEligibleListDialog(item),
            tooltip: 'tooltips.check-problems-in-optin',
            isVisible: (item) => !!item.notExportStatusReasons && item.notExportStatusReasons.length > 0
        },
        {
            icon: 'create',
            onClick: (item) => this.navigateToUpdate(item),
            tooltip: 'tooltips.edit',
            isDisabled: (item) =>  this.isViewMode
        },
        {
            icon: 'delete',
            onClick: (item) => this.removeOptin(item),
            isDisabled: (item) =>
                (item.statusDescription &&
                    item.statusDescription === 'EMPLOYEE_AND_EMAIL_KNOW') ||
                (item.inProcessOfReturningConsultancy && item.status === STATUS_DATA_EXPORT_PENDING) ||
                this.isViewMode,
            tooltip: 'tooltips.delete',
            confirmActionBeforeClick: () => true,
            getConfirmActionText: (item: { status: string }) =>
                item.status !== STATUS_INACTIVE
                    ? 'operations.disable.confirmation'
                    : 'operations.enable.confirmation',
        },
    ];
    requiredSettingsData: RequiredSettings;
    disableImport = true;
    currentSystemType: string;

    constructor(
        private dialog: MatDialog,
        private toastService: ToastrService,
        private companiesService: CompaniesService,
        private optinService: OptinsService,
        private bookkeepingKeyService: BookkeepingKeyService
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.setRequiredSettingsData();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    removeOptin(item) {
        this.spinner.show();
        this.optinService
            .removeOptin(item.id)
            .pipe(
                map((data) => data.message),
                tap(() => this.spinner.hide()),
                catchError(() => this.spinner.hide())
            )
            .subscribe({
                next: () => this.notification.disableSuccess(),
                error: (err) => {
                    if (err) {
                        this.notification.error(err);
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}], details: ${err}`
                        );
                    } else {
                        this.notification.error(
                            `An error occurred while updating optin status`
                        );
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${REMOVE_OPTIN_FUNCTION}].`
                        );
                    }
                },
            });
    }

    setRequiredSettingsData() {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_NOT_EQUALS,
                value: 'rejected'.toUpperCase(),
            },
        ];
        forkJoin({
            company: this.companiesService.getCompany(),
            questions: this.companiesService.getQuestionsByCompany(
                this.userService.companyId
            ),
            address: this.companiesService
                .getMainAddressByCompany(
                    this.userService.companyId,
                    COMPANIES_COLLECTION
                )
                .pipe(
                    map((addresses) => addresses[0]),
                    take(1)
                ),
            wageAccountingSystem: this.service
                .search(
                    `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${QUESTIONS_COLLECTION}/payroll/${WAGE_ACCOUNTING_SYSTEM_COLLECTION}`,
                    filter
                )
                .pipe(take(1)),
            deadlineForBookingSuggestions: this.service
                .search(
                    `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${QUESTIONS_COLLECTION}/payroll/${DEADLINE_FOR_BOOKING_SUGGESTIONS_COLLECTION}`,
                    filter
                )
                .pipe(take(1)),
            differentMinimumWage: this.service
                .search(
                    `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${QUESTIONS_COLLECTION}/payroll/${DIFFERENT_MINIMUM_WAGE_COLLECTION}`,
                    filter
                )
                .pipe(take(1)),
            collectiveAgreement: this.service
                .search(
                    `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${QUESTIONS_COLLECTION}/payroll/${COLLECTIVE_AGREEMENT_COLLECTION}`,
                    filter
                )
                .pipe(take(1)),
            bookkeepingKeys: this.bookkeepingKeyService
                .fetchAllKeysGroupedByCard()
                .pipe(take(1)),
        }).subscribe(
            ({
                company,
                questions,
                address,
                wageAccountingSystem,
                deadlineForBookingSuggestions,
                differentMinimumWage,
                collectiveAgreement,
                bookkeepingKeys,
            }) => {
                this.disableImport = false;
                this.requiredSettingsData = this.verifyRequiredSettingsData({
                    company,
                    questions,
                    address,
                    wageAccountingSystem,
                    deadlineForBookingSuggestions,
                    differentMinimumWage,
                    collectiveAgreement,
                    bookkeepingKeys,
                });
            }
        );
    }

    getFilter() {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [
                    EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE,
                    EmployeeStatusEnum.DATA_EXPORT_PENDING,
                    EmployeeStatusEnum.DATA_MISSING,
                ],
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        return filter;
    }

    navigateToUpdate(item: any) {
        this.navigate([this.getRouterURL(), 'update', item.id]);
    }

    getStatusValue(employee: any) {
        if (employee.inProcessOfReturningConsultancy) {
            if (employee.status === STATUS_DATA_EXPORT_PENDING) {
                return STATUS_DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS;
            }
            if (employee.status === STATUS_CONSULTATION_APPROVAL_PENDING) {
                return STATUS_CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS;
            }
        }
        return employee.status;
    }

    hasAnyApprovedOptin() {
        return (
            this.items &&
            this.items.filter(
                (item) =>
                    item.status === EmployeeStatusEnum.DATA_EXPORT_PENDING ||
                    item.status === EmployeeStatusEnum.DATA_MISSING ||
                    item.status === EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE
            ).length > 0
        );
    }

    getCollectionURL() {
        return EMPLOYEES_COLLECTION;
    }

    getRouterURL() {
        return 'optins';
    }

    openNotEligibleListDialog(item) {
        this.dialog
            .open(NotEligibleListModalComponent, {
                panelClass: 'curved-modal',
                minWidth: '35vw',
                data: {
                    items: item.notExportStatusReasons,
                    missingFields: item.missingFields
                },
            })
            .afterClosed();
    }

    errorReportNotInExportStatusReasons(item) {
        return item.notExportStatusReasons && !item.notExportStatusReasons.includes(item.errorReport);
    }

    openChooseFileModal() {
        if (this.currentSystemType) {
            return this.showImportModal();
        }
        return this.redirectToPayroll();
    }

    changeStatus(item) {
        super.changeStatus(item);
    }

    updateTotalItems() {
        // this.setTotalItems().subscribe();
    }

    private showImportModal() {
        return this.dialog
            .open(ChooseImportationFileComponent, {
                disableClose: true,
                maxWidth: '65vw',
                minWidth: '45vw',
                data: {
                    items: this.items,
                    systemSelected: this.currentSystemType,
                },
                panelClass: 'choose-importation-file-modal',
            })
            .afterClosed();
    }

    public openOptinSettingModal() {
        if (!this.currentSystemIsBlockedForImport()) {
            const isRequiredSettings = this.isRequiredSettingsData(
                this.requiredSettingsData
            );
            if (isRequiredSettings) {
                this.openChooseFileModal();
            } else {
                this.dialog
                    .open(RequiredSettingsComponent, {
                        minWidth: '35vw',
                        data: {
                            companyId: this.userService.loggedUser.companyId,
                            requiredSettingsData: this.requiredSettingsData,
                        },
                    })
                    .afterClosed();
            }
        }
    }

    isRequiredSettingsData(settings: RequiredSettings): boolean {
        if (settings) {
            return (
                settings.questions &&
                settings.address &&
                settings.deadlineForBookingSuggestions &&
                settings.wageAccountingSystem &&
                settings.differentMinimumWage &&
                settings.collectiveAgreement &&
                settings.wageTypesTaxable &&
                settings.wageTypesTaxFree &&
                settings.otherServices &&
                settings.services
            );
        }
    }

    private verifyRequiredSettingsData({
        company,
        questions,
        address,
        wageAccountingSystem,
        deadlineForBookingSuggestions,
        differentMinimumWage,
        collectiveAgreement,
        bookkeepingKeys,
    }): RequiredSettings {
        if (wageAccountingSystem) {
            const system = wageAccountingSystem.filter(
                (item) => item.status === STATUS_ACTIVE
            );
            this.currentSystemType = system[0].type;
        }

        return {
            questions:
                !!questions &&
                this.verifyIfSectionIsOk(
                    questions,
                    this.companiesService.questionsKeys
                ) &&
                !questions.pendentApproval,
            address:
                !!address &&
                this.verifyIfSectionIsOk(
                    address,
                    this.companiesService.addressKeys
                ) &&
                !address.pendentApproval,
            wageAccountingSystem:
                wageAccountingSystem &&
                wageAccountingSystem.filter(
                    (item) => item.status === STATUS_ACTIVE
                ).length > 0,
            deadlineForBookingSuggestions:
                !!deadlineForBookingSuggestions &&
                deadlineForBookingSuggestions.filter(
                    (item) => item.status === STATUS_ACTIVE
                ).length > 0,
            differentMinimumWage:
                !!differentMinimumWage &&
                differentMinimumWage.filter(
                    (item) => item.status === STATUS_ACTIVE
                ).length > 0,
            collectiveAgreement:
                !!collectiveAgreement &&
                collectiveAgreement.filter(
                    (item) => item.status === STATUS_ACTIVE
                ).length > 0,
            otherServices: !this.hasRequiredEmptyKeys(
                bookkeepingKeys.otherServices
            ),
            services: !this.hasRequiredEmptyKeys(bookkeepingKeys.services),
            wageTypesTaxable: !this.hasRequiredEmptyKeys(bookkeepingKeys.wageTypes),
            wageTypesTaxFree: !this.hasRequiredEmptyKeys(bookkeepingKeys.wageTypes),
        };
    }

    private hasRequiredEmptyKeys(items = []) {
        for (const item of items) {
            if (item.status == 'ACTIVE') {
                if (item.isTaxable && item.tax?.requiredColumns) {
                    for (const property of item.tax.requiredColumns) {
                        if (lodash.isEmpty(item.tax[property])) {
                            return true;
                        }
                    }
                }

                for (const property of item?.requiredColumns ?? []) {
                    if (lodash.isEmpty(item[property])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private verifyIfSectionIsOk(list, keys) {
        for (const key of keys) {
            const disabledData = this.companiesService.companyDisableData[key];
            if (disabledData && disabledData.association) {
                if (
                    list[key] === disabledData.enableAssociationValue &&
                    (list[disabledData.association] === null ||
                        list[key] === undefined)
                ) {
                    return false;
                }
            } else {
                if (
                    !this.companiesService.isAssociation(
                        this.companiesService.companyDisableData,
                        key
                    ) &&
                    (list[key] === null || list[key] === undefined)
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    private redirectToPayroll() {
        return this.toastService
            .warning(
                this.translateService.instant(
                    'optins.not-found-wage-accounting-system'
                )
            )
            .onTap.pipe(
                concatMap(() =>
                    this.router.navigateByUrl(
                        '/bookkeeping-suggestions/payroll-bookkeeping'
                    )
                )
            );
    }

    getNewsApprovedOptins() {
        return this.items?.length;
    }

    currentSystemIsBlockedForImport() {
        return (
            SYSTEMS_BLOCKED_FOR_IMPORT.includes(this.currentSystemType)
        );
    }
}
