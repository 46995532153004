import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateChildFn,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { concatMap } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { UserModel } from 'src/app/shared/models/user.model';

function hasAnyAuthority(
    userService: UserService,
    loggedUser: UserModel,
    permissions: string[]
) {
    if (permissions.length) {
        return userService.hasAnyAuthority(permissions);
    }
    return true;
}

function hasAnyViewAuthority(
    userService: UserService,
    loggedUser: UserModel,
    viewPermission: string[]
) {
    if (viewPermission) {
        return userService.hasAnyViewAuthority(viewPermission);
    }
    return !!userService.isFinsteinHelper();
}

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const spinnerService = inject(NgxSpinnerService);
    spinnerService.show('authGuardSpinner');
    
    const userService = inject(UserService);
    const router = inject(Router);

    const permissions = route.data.permissions || [];
    const onlyCompanyAdmin = route.data.onlyCompanyAdmin || false;
    const onlyFinsteinUsers = route.data.onlyFinsteinUsers || false;
    const onlyHelper = route.data.onlyHelper || false;
    const viewPermission = route.data.viewPermission;
    return userService.loadCurrentUser().pipe(
        take(1),
        concatMap(() => userService.isLogged()),
        map((isLogged) => {
            if (isLogged) {
                const loggedUser = userService.loggedUser;
                if (onlyFinsteinUsers && !loggedUser.finsteinUser) {
                    router.navigate(['/authentication']);
                    return false;
                } else if (onlyFinsteinUsers) {
                    return loggedUser.finsteinUser;
                } else if (onlyHelper) {
                    return userService.isFinsteinHelper();
                } else if (onlyCompanyAdmin) {
                    return (loggedUser.companyResponsible || userService.isFinsteinHelper());
                } else {
                    const anyAuthority = hasAnyAuthority(
                        userService,
                        loggedUser,
                        permissions
                    );
                    const viewMode =
                        !anyAuthority &&
                        hasAnyViewAuthority(
                            userService,
                            loggedUser,
                            viewPermission
                        );
                    route.data = { ...route.data, viewMode };
                    return anyAuthority || viewMode;
                }
            }
            router.navigate(['/authentication']);
            return false;
        }),
        finalize(() => {
            spinnerService.hide('authGuardSpinner');
        })
    );
};

export const canActivateChild: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);