import { Injectable } from '@angular/core';
import * as lodash from 'lodash';
import moment from 'moment';
import { Papa } from 'ngx-papaparse';
import { map, switchMap, take } from 'rxjs/operators';
import { FirestoreService } from 'src/app/core/services/firestore.service';
import { UserService } from 'src/app/core/services/user.service';
import { AccountingSystemsEnum } from 'src/app/shared/enums/accounting-systems.enum';
import * as XLSX from 'xlsx';
import {
    COMPANIES_COLLECTION,
    ENCODING_READER_CSV_FILE,
    FIELD_TYPE_SERVICE,
    FIELD_TYPE_WAGE_KEYS, HEALTH_INSURANSE_VALUES, IMPORT_SETTINGS_COLLECTION,
    OPTINS_IMPORT_SETTINGS_ID,
    RELIGION_REGEX,
    SERVICE_RETIREMENT,
    WEEKLY_WORKING_HOURS
} from '../../../../app.constants';
import { OptinsImportFileTypeEnum } from '../../../../shared/enums/optins-import-file-type.enum';
import { checkFileExtension } from '../../../../utils/file.utils';
import { BookkeepingKeyService } from '../../bookkeeping-suggestions/bookkeeping-keys/services/bookkeeping-key.service';
import { AbstractImportationStrategy } from './abstract-importation-strategy';
@Injectable()
export class EdlohnImportationStrategy extends AbstractImportationStrategy {

    optins = [];

    public progressData = {
        startProcessFile: {
            ratio: 2.5
        },
        finishProcessFile: {
            ratio: 2.5
        },
        extractPersonalData: {
            ratio: 20
        },
        extractFinancialData: {
            ratio: 20
        },
        extractWageHistoryData: {
            ratio: 20
        },
        normalizeData: {
            ratio: 30
        }
    };

    constructor(private papa: Papa,
                private userService: UserService,
                private bookkeepingKeyService: BookkeepingKeyService,
                private firestoreService: FirestoreService,
    ) {
        super();
    }

    async import(mainFile: File, wageHistory: File, optins): Promise<any> {
        try {
            this.optins = optins;
            const fillDataList = await this.processFile(mainFile, OptinsImportFileTypeEnum.PERSONAL_DATA).then((data) => data);
            const benefitList = await this.processFile(wageHistory, OptinsImportFileTypeEnum.WAGE_HISTORY_DATA)
                .then((data) => data);
            if (this.userService.isDebugImportFileMode) {
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    mainFile,
                    this.userService.companyId
                );
                await this.saveFilesInFirestoreForFutureDebug(
                    this.firestoreService,
                    wageHistory,
                    this.userService.companyId
                );
            }
            return await this.extractOptinsFromImportedLists(fillDataList, benefitList).then((data) => data);
        } catch (e) {
            throw new Error(e);
        }
    }

    private processFile(file: File, type: OptinsImportFileTypeEnum) {
        this.emitProgress(this.progressData.startProcessFile.ratio);
        if (checkFileExtension(file, ['.csv'])) {
            return this.processCSV(file);
        } else if (checkFileExtension(file, ['.txt'])) {
            return this.processTxt(file);
        } else {
            return this.processXLSX(file);
        }
    }

    private processTxt(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsText(file, ENCODING_READER_CSV_FILE);
                fileReader.onerror = (err) => {
                    reject(err);
                };
                fileReader.onload = (e) => {
                    const csv: any = fileReader.result;
                    const allTextLines = csv.split(/\r|\n|\r/);
                    const headers = allTextLines[0].split(/\n|\t|;/);
                    const result = [];
                    for (let i = 1; i < allTextLines.length; i++) {
                        const data = allTextLines[i].split(/\n|\t|;/);
                        if (data.length === headers.length) {
                            const obj = {};
                            for (let j = 0; j < headers.length; j++) {
                                const headerFormatted = headers[j].replace(new RegExp('"', 'g'), '');
                                obj[headerFormatted] = data[j].replace(new RegExp('"', 'g'), '');
                            }
                            result.push(obj);
                        }
                    }
                    this.emitProgress(this.progressData.finishProcessFile.ratio);
                    resolve(result);
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private processXLSX(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onerror = (err) => {
                    reject(err);
                };
                fileReader.onload = (e) => {
                    const arrayBuffer: any = fileReader.result;
                    const data = new Uint8Array(arrayBuffer);
                    const arr = [];
                    for (let i = 0; i !== data.length; ++i) {
                        arr[i] = String.fromCharCode(data[i]);
                    }
                    const bstr = arr.join('');
                    const workbook = XLSX.read(bstr, {type: 'binary', cellDates: true});
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    this.emitProgress(this.progressData.finishProcessFile.ratio);
                    resolve(XLSX.utils.sheet_to_json(worksheet, {raw: true}));
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private processCSV(file: File) {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsText(file, ENCODING_READER_CSV_FILE);
                reader.onerror = (err) => {
                    reject(err);
                };
                reader.onload = (event: any) => {
                    this.papa.parse(event.target.result, {
                        skipEmptyLines: true,
                        header: true,
                        encoding: ENCODING_READER_CSV_FILE,
                        complete: async (results) => {
                            this.emitProgress(this.progressData.finishProcessFile.ratio);
                            resolve(results.data);
                        }
                    });
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    private extractOptinsFromImportedLists(data: any, benefitList: any) {
        return new Promise((resolve, reject) => {
            this.service.getById(
                OPTINS_IMPORT_SETTINGS_ID,
                `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${IMPORT_SETTINGS_COLLECTION}`
            ).pipe(
                take(1),
                switchMap(fields => {
                    return this.bookkeepingKeyService.fetchAllKeys(AccountingSystemsEnum.EDLOHN)
                        .pipe(map(allKeys => ({allKeys, fields})));
                })
            ).subscribe({
                next: ({ allKeys, fields }) => {
                    try {
                        this.emitProgress(this.progressData.extractPersonalData.ratio);
                        // PERSONAL DATA
                        const allFields = { ...fields.personalData, ...fields.financialData };
                        const optinsGeneralData = this.extractPersonalDataByOptins(
                            data,
                            allFields,
                            this.optins
                        );
                        this.emitProgress(this.progressData.extractFinancialData.ratio)
                        // WAGE HISTORY DATA
                        const fieldsWageKeyHistory = allKeys.keys
                            .map((service) => {
                                const line = {
                                    key: service.id,
                                    synonyms: service.code ?? [],
                                };
                                if (service.tax) {
                                    line.synonyms.push(...(service.tax.code ?? []));
                                }
                                return line;
                            })
                            .reduce(
                                (prev, curr) => {
                                    prev[curr.key] = { synonyms: curr.synonyms, type: FIELD_TYPE_WAGE_KEYS };
                                    return prev;
                                },
                                {},
                            );

                        const fieldsServiceHistory = this.getFieldsServiceHistory(allKeys);

                        const optinsWageHistoryData = this.extractWageHistoryDataByImportedUsers(
                            benefitList,
                            {
                                registration: allFields.registration,
                                billingMonth: allFields.billingMonth,
                                ...fieldsWageKeyHistory,
                                ...fieldsServiceHistory,
                            },
                            optinsGeneralData
                        );

                        if (optinsWageHistoryData.length === 0) {
                            this.openDialogWageHistoryDataNotFound();
                            reject('The wage history list cannot be empty.');
                        }

                        const optinsPersonalDataHandled = this.removeDuplicates(optinsGeneralData);
                        const allData = this.joinAllData(
                            optinsPersonalDataHandled,
                            optinsWageHistoryData,
                        );
                        const result = this.normalizeData(allData, allKeys);
                        this.emitProgress(this.progressData.normalizeData.ratio);
                        this.progress = 0;
                        resolve(result);
                    } catch (e) {
                        reject(e);
                    }
                },
                error: error => {
                    reject(error);
                }
            });
        });
    }

    private removeDuplicates(optinsPersonalData: any[]) {
        return lodash.uniqBy(optinsPersonalData, 'registration');
    }

    private joinAllData(
        personalData: any[],
        wageHistoryData: any[],
    ) {
        const result = [];
        if (personalData.length === 0) {
            this.emitProgress(this.progressData.normalizeData.ratio);
        } else {
            const progressJumps =
                this.progressData.normalizeData.ratio / personalData.length;
            for (const optIn of personalData) {
                if (!optIn.wageData) {
                    optIn.wageData = [];
                }

                optIn.wageHistoryData = wageHistoryData.filter(
                    wageHistory => wageHistory.registration === optIn.registration
                );

                this.emitProgress(progressJumps);
                result.push(optIn);
            }
        }
        return result;
    }

    private extractPersonalDataByOptins(dataImported: any[], fields, optins: any[]) {
        const result = [];
        if (dataImported.length === 0) {
            this.emitProgress(this.progressData.extractPersonalData.ratio);
        } else {
            const progressJumps = (this.progressData.extractPersonalData.ratio / dataImported.length);

            for (const importedUser of dataImported) {
                const user = this.buildObject(importedUser, fields);
                const foundOptin = this.findOptinUser(user, optins);
                if (foundOptin) {
                    user.lastModifiedAt = foundOptin.lastModifiedAt;
                    result.push(user);
                }
                this.emitProgress(progressJumps);
            }
        }
        return result;
    }

    private extractWageHistoryDataByImportedUsers(wageHistoryData: any[], fields, importedUsers: any[]) {
        const result = [];
        if (wageHistoryData.length === 0) {
            this.emitProgress(this.progressData.extractWageHistoryData.ratio);
        } else {
            const progressJumps = (this.progressData.extractWageHistoryData.ratio / importedUsers.length);
            for (const importedData of wageHistoryData) {
                const data: any = this.buildObject(importedData, fields);
                const isValidWageHistoryData = Object.keys(data).filter(
                    (key) => key !== 'registration' && key !== 'billingMonth'
                ).length;
                if (isValidWageHistoryData && this.matchOptinFinancialData(data, importedUsers)) {
                    result.push(
                        ...Object.keys(fields)
                            .map((key) => {
                                return data[key];
                            })
                            .filter((e) => !!e && e?.amount)
                            .map((e) => ({
                                registration: e.registration,
                                billingMonth: `${e.billingMonth.slice(
                                    0,
                                    4
                                )}-${e.billingMonth.slice(4, 6)}`,
                                amount: e.amount,
                                wageTypeNumber: e.wageTypeNumber || e.serviceKey,
                                paymentFrequence: e.paymentFrequence,
                            }))
                    );
                }
                this.emitProgress(progressJumps);
            }
            return result;
        }
    }

    private normalizeData(items: any[], allKeys: { keys: any }) {
        for (const item of items) {
            // Remove characters keeping only numbers
            if (item.personalGroupKey) {
                item.personalGroupKey = Number(item.personalGroupKey.slice(0,3));
            }

            if (item.taxClass) {
                item.taxClass = {
                    'St-Klasse I': 1,
                    'St-Klasse II': 2,
                    'St-Klasse III': 3,
                    'St-Klasse IV': 4,
                    'St-Klasse V': 5,
                    'St-Klasse VI': 6,
                }[item.taxClass] ?? 0;
            }

            item.annualTaxAllowance = (item.annualExemption || 0);
            item.healthInsuranceBasicContribution =
                (item.privateHealthInsuranceTotalContribution || 0) +
                (item.privateCareInsuranceTotalContribution || 0);
            
            item.religion = !!item.religion && !RELIGION_REGEX.test(item.religion);

            item.gender = this.getGender(item.gender);


            if (!item.taxFactor) {
                item.taxFactor = 1;
            }

            if (!item.children) {
                item.children = 0;
            }

            if (!item.weeklyWorkingHours || item.weeklyWorkingHours === 0) {
                item.weeklyWorkingHours = WEEKLY_WORKING_HOURS;
            }

            if (item.privateHealthInsurance) {
                item.privateHealthInsurance = Number((item.privateHealthInsurance.match(/\d+/g) ?? [])
                    .join('')) === 0;
            }

            if (item.mandatoryContributionHealth) {
                item.mandatoryContributionHealth = Number((item.mandatoryContributionHealth.match(/\d+/g) ?? [])
                    .join(''));
            }

            if (item.mandatoryContributionRetirement) {
                item.mandatoryContributionRetirement = Number((item.mandatoryContributionRetirement.match(/\d+/g) ?? [])
                .join(''));
            }

            if (item.mandatoryContributionUnemployment) {
                item.mandatoryContributionUnemployment = Number((item.mandatoryContributionUnemployment.match(/\d+/g) ?? [])
                    .join(''));
            }

            if (item.mandatoryContributionCare) {
                item.mandatoryContributionCare = Number((item.mandatoryContributionCare.match(/\d+/g) ?? [])
                    .join(''));
            }

            item.contributionGroupKey = [
                item.mandatoryContributionHealth,
                item.mandatoryContributionRetirement,
                item.mandatoryContributionUnemployment,
                item.mandatoryContributionCare,
            ].join('-');

            item.type = 'edlohn';
            item.registration = item.registration?.toString();

            if (item.healthInsuranceNumber) {
                let healthInsuranceValue = (item.healthInsuranceNumber.match(/\d+/g) ?? []).join('');
                if (healthInsuranceValue?.length === 0) {
                    healthInsuranceValue = HEALTH_INSURANSE_VALUES[item.healthInsuranceNumber];
                }

                if (healthInsuranceValue && healthInsuranceValue !== '0') {
                    item.healthInsuranceNumber = healthInsuranceValue;
                } else {
                    delete item.healthInsuranceNumber;
                }
            }

            if (item.wageHistoryData) {
                const maxDateOfWageList = new Date(
                    Math.max(
                        ...item.wageHistoryData.map((e) => {
                            return moment(e.billingMonth, 'YYYY-MM').toDate().getTime();
                        })
                    )
                );
                const maxBillingMonth = new Date(maxDateOfWageList);
                item.billingMonth = moment(maxBillingMonth).format('YYYY-MM');

                const basicWageKeys: Array<string> = allKeys.keys
                    .find((e) => e.id === 'basic-wage')
                    .code;

                const bkvKeys: Array<number> = allKeys.keys
                    .find((e) => e.id === 'bkv')
                    .code;

                const regularTaxableAllowanceKeys: Array<string> = allKeys.keys
                    .find((e) => e.id === 'regular-taxable-allowance')
                    .code;

                item.wageData = [];
                for (let index = 0; index < item.wageHistoryData.length; index++) {
                    const wageHistory = item.wageHistoryData[index];
                    if (
                        wageHistory.billingMonth === item.billingMonth &&
                        (
                            basicWageKeys.includes(wageHistory.wageTypeNumber) ||
                            bkvKeys.includes(wageHistory.wageTypeNumber) ||
                            regularTaxableAllowanceKeys.includes(wageHistory.wageTypeNumber)
                        )
                    )  {
                        item.wageHistoryData[index] = null;
                        item.wageData.push({
                            registration: wageHistory.registration,
                            billingMonth: wageHistory.billingMonth,
                            amount: wageHistory.amount,
                            serviceKey: wageHistory.wageTypeNumber,
                            paymentFrequence: wageHistory.paymentFrequence,
                            isFromWageHistory: true,
                        });
                    } else if (wageHistory.paymentFrequence) {
                        wageHistory.onePayment = wageHistory.paymentFrequence.localeCompare(
                            'jährlich',
                            'de',
                            { sensitivity: 'base' }
                        ) === 0;
                    }
                }
                // Only not null elements
                item.wageHistoryData = item.wageHistoryData.filter(e => !!e);
            }

            delete item.grossSalary;
        }
        return items;
    }
}
