import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { CompaniesService } from 'src/app/modules/administration/company-leads/companies.service';
import { environment } from 'src/environments/environment';
import { EMPLOYEES_COLLECTION, GENERATE_OPTIN_INVITE_LINK_FUNCTION, STATUS_NOT_ELIGIBLE_FOR_OPTIMIZATION } from '../../../../app.constants';
import { BaseComponent } from '../../../../core/abstractions/base.component';
import { OptinsListApprovedComponent } from './approved/optins-list-approved.component';
import { OptinsListDataCompletedComponent } from './data-completed/optins-list-data-completed.component';
import { OptinsListPendentComponent } from './pendent/optins-list-pendent.component';
import { ActivatedRoute } from '@angular/router';
import { OptinsListNotEligibleComponent } from './not-eligible/optins-list-not-eligible.component';

declare let $;

@Component({
    templateUrl: './optins-list.component.html',
    styleUrls: ['./optins-list.component.scss'],
})
export class OptinsListComponent
    extends BaseComponent
    implements OnInit, AfterViewInit {
    currentTab;
    currentAccountingSystem: any;
    isHelper: boolean

    @ViewChild('pendent', {static: false})
    pendentList: OptinsListPendentComponent;
    @ViewChild('approved', {static: false})
    approvedList: OptinsListApprovedComponent;
    @ViewChild('dataCompleted', {static: false})
    dataCompletedList: OptinsListDataCompletedComponent;
    @ViewChild('notEligible', {static: false})
    notEligibleList: OptinsListNotEligibleComponent;

    @ViewChild('popoverHook', {static: false})
    releaseToCopPopover: ElementRef;

    fragmentByComponent;

    popoverArrow: Element;
    popoverOpened = false;

    constructor(
        private companiesService: CompaniesService,
        public elementRef: ElementRef,
        public renderer: Renderer2,
        private activatedRoute: ActivatedRoute
    ) {
        super();
        this.renderer.listen('window', 'click', (event: any) => {
            if (event.target !== this.releaseToCopPopover?.nativeElement &&
                event.target?.offsetParent?.localName !== 'ngb-popover-window') {
                this.closePopover();
            }
        });
        if (!this.userService.hasAnyAuthority(['process#holder#change-employee-master-data',
            'process#standIn#change-employee-master-data'])) {
            if (this.userService.hasAnyViewAuthority('view#change-employee-master-data')) {
                this.isViewMode = true;
            }
        }

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.companiesService
            .getCurrentWageAccountingSystem()
            .subscribe((data) => (this.currentAccountingSystem = data));

        this.popoverArrow = this.elementRef.nativeElement.querySelector('#popoverArrow');
        this.isHelper = this.userService.isFinsteinHelper();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.fragmentByComponent = {
            pendent: this.pendentList,
            approved: this.approvedList,
            'data-completed': this.dataCompletedList,
            'not-eligible': this.notEligibleList,
        };
        this.activatedRoute.fragment.subscribe((fragment) => {
            if (fragment) {
                this.clickTab(fragment);
                $(`#link-${fragment}`).click();
            } else {
                this.clickTab('pendent');
            }
        });
    }

    getCollectionURL() {
        return EMPLOYEES_COLLECTION;
    }

    hasAnyPermission(value: string | string[]) {
        return this.companiesService.hasAnyPermission(value) || this.isViewMode;
    }

    clickTab(tab: string) {
        if (this.currentTab !== tab) {
            this.fragmentByComponent[tab]?.table.ngAfterViewInit();
            this.currentTab = tab;
            $('.tab-content').children().removeClass('show active');
            this.router.navigate(['./optins'], {fragment: tab, queryParamsHandling: 'preserve'});
            $(`#${tab}`).click();
        }
    }

    get generateOptinLink() {
        return GENERATE_OPTIN_INVITE_LINK_FUNCTION;
    }

    getNewsPendentOptins() {
        if (this.pendentList && this.pendentList.items?.length) {
            return `(${this.pendentList.items.length})`;
        }
    }

    getNewsApprovedOptins() {
        if (this.approvedList && this.approvedList.items?.length) {
            return `(${this.approvedList.items.length})`;
        }
    }

    getNewsNotEligibleOptins() {
        if (this.notEligibleList && this.notEligibleList.items?.length) {
            return `(${this.notEligibleList.items.length})`;
        }
    }

    getNewsDataCompletedOptins() {
        if (
            this.dataCompletedList &&
            this.dataCompletedList.items &&
            this.dataCompletedList.items.filter(
                (e) => e.status !== STATUS_NOT_ELIGIBLE_FOR_OPTIMIZATION
            ).length > 0
        ) {
            return `(${this.dataCompletedList.items.length})`;
        }
    }

    getTooltipCopLink(): string {
        return this.translateService.instant(
            'optins.tabs.data-completed.tooltip.email-template.text-4',
            {
                copLink: `${environment.websiteBaseURL}/consultancy-process`
            }
        );
    }

    isUserCompanyResponsible() {
        return this.userService.loggedUser?.companyResponsible;
    }

    onHover() {
        if (this.popoverOpened) {
            this.renderer.setStyle(this.popoverArrow, 'opacity', '0');
        } else {
            this.renderer.setStyle(this.popoverArrow, 'opacity', '1');
        }
        this.popoverOpened = !this.popoverOpened;
    }

    closePopover() {
        this.renderer.setStyle(this.popoverArrow, 'opacity', '0');
        this.popoverOpened = false;
    }

    hasAnyHumanResourcePermission() {
        return this.hasAnyPermission([
            'process#holder#change-employee-master-data',
            'process#standIn#change-employee-master-data',
            'inform#holder#change-employee-master-data',
            'inform#standIn#change-employee-master-data',
            'process#holder#sign-additions-to-employment-contracts',
            'process#standIn#sign-additions-to-employment-contracts',
            'inform#holder#sign-additions-to-employment-contracts',
            'inform#standIn#sign-additions-to-employment-contracts',
            'process#holder#release-employee-for-salary-increase',
            'process#standIn#release-employee-for-salary-increase',
            'inform#holder#release-employee-for-salary-increase',
            'inform#standIn#release-employee-for-salary-increase',
            'confirm#holder#release-employee-for-salary-increase',
            'confirm#standIn#release-employee-for-salary-increase',
        ]);
    }

    getScheduleSupportAppointmentLink() {
        return this.translateService.instant(
            'optins.tabs.data-completed.tooltip.email-template.text-9',
            {
                scheduleLink: `${environment.scheduleSupportAppointmentLink}/saskia-soezeri/supportmeeting`
            }
        );
    }
}
