import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import 'firebase/auth';
import {
    browserSessionPersistence,
    EmailAuthProvider,
    getAuth,
    setPersistence,
} from 'firebase/auth';
import moment from 'moment';
import { EMPTY, from, Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { FireLoggingService } from 'src/app/core/services/fire-logging.service';
import {
    CHANGE_PASSWORD_FUNCTION, FIREBASE_URL_FUNCTIONS,
    GET_USER_BY_RESET_PASSWORD_KEY,
    REMOVE_FINSTEIN_HELPER_COMPANY_DATA_FUNCTION,
    REQUEST_RESET_PASSWORD_FUNCTION,
} from '../../../app.constants';
import { BaseService } from '../../../core/abstractions/base-service';
import { UserService } from '../../../core/services/user.service';
import { ProgressToasterService } from 'src/app/shared/components/progress-toaster/services/progress-toaster.service';
@Injectable({
    providedIn: 'root',
})
export class AuthFirebaseService {
    constructor(
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private service: BaseService<any>,
        private realtime: AngularFireDatabase,
        private dialog: MatDialog,
        private ngZone: NgZone,
        private fireLoggingService: FireLoggingService,
        private router: Router,
        private http: HttpClient,
        private progressToasterService: ProgressToasterService
    ) {}

    signIn({ email, password }) {
        return from(setPersistence(getAuth(), browserSessionPersistence)).pipe(
            concatMap(() =>
                this.afAuth.signInWithEmailAndPassword(email, password)
            ),
            switchMap((success) => {
                if (success.user) {
                    return this.setUserSession(success.user.uid).pipe(
                        switchMap(() =>
                            this.userService.listenForChangesAtUser(
                                success.user.uid
                            )
                        ),
                        tap(() =>
                            this.listenUserSessionChanged(success.user.uid)
                        )
                    );
                }
                this.fireLoggingService.sendAlertLog(
                    `Error when trying signIn, not found user with email: ${email}`
                );
                return throwError(() => 'User not found');
            }),
            catchError((err) => {
                return this.signOut().pipe(
                    concatMap(() => throwError(() => err))
                );
            })
        );
    }

    async reauthenticateWithCredential(newPassword: string) {
        const email = this.userService.loggedUser.email;
        const user = await this.afAuth.currentUser;
        const credential = EmailAuthProvider.credential(
            email,
            newPassword
        );
        return user.reauthenticateWithCredential(credential).then().catch(err => {
            this.fireLoggingService.sendErrorLog(`An error occurred while reauthenticate after changing password, details: email:${email} - ${ err.message || JSON.stringify(err) }`);
        });
    }

    getUserByResetPasswordKey(key: string) {
        return this.service.callFunction(
            { key },
            GET_USER_BY_RESET_PASSWORD_KEY,
            { type: 'callable', httpMethod: 'POST' }
        );
    }

    requestResetPassword(email: string) {
        return this.service.callFunction(
            { email },
            REQUEST_RESET_PASSWORD_FUNCTION,
            { type: 'callable', httpMethod: 'POST' }
        );
    }

    changePassword(email: string, password: string) {
        return this.service.callFunction(
            { email, password },
            CHANGE_PASSWORD_FUNCTION,
            { type: 'callable', httpMethod: 'POST' }
        );
    }

    signOut() {
        let obs = of(true);
        if (
            !this.userService.loggedUser?.finsteinUser &&
            this.userService.isFinsteinHelper()
        ) {
            obs = this.service.callFunction(
                {
                    id: this.userService.loggedUser.id,
                },
                REMOVE_FINSTEIN_HELPER_COMPANY_DATA_FUNCTION,
                { type: 'callable', httpMethod: 'POST' }
            );
        }
        return obs.pipe(
            tap(() => {
                if (this.userService?.loggedUser?.id) {
                    this.realtime.database
                        .ref(
                            `portal/user-sessions/${this.userService.loggedUser.id}`
                        )
                        .off('value');
                }
                this.progressToasterService.hideProgressToaster();
                this.userService.reset();
            }),
            concatMap(() => this.afAuth.signOut())
        );
    }

    async listenUserSessionChanged(userId: string) {
        const userSession = this.realtime.database.ref(
            `portal/user-sessions/${userId}`
        );
        userSession.off('value', () => {});
        userSession.on(
            'value',
            (snapshot) => {
                const val = snapshot.val();
                if (this.userService.browserClientData) {
                    if (
                        (val && val.userAgent !== navigator.userAgent) ||
                        (val.ip !== this.userService.browserClientData?.ip)
                    ) {
                        this.ngZone.run(() => {
                            this.dialog.closeAll();
                            this.router.navigate(['/authentication'], {
                                queryParams: {
                                    disableRedirect: true,
                                    multipleSession: true,
                                },
                            });
                        });
                    }
                }
            },
            (err) => {
                this.fireLoggingService.sendAlertLog(
                    `Error when trying listenUserSessionChanged. Details: ${err.message}`
                );
            }
        );
    }

    public setUserSession(userId: string): Observable<boolean | void> {
        return this.http.get(`${FIREBASE_URL_FUNCTIONS}getClientIp`).pipe(
            catchError((err) => {
                this.fireLoggingService.sendAlertLog(
                    `Error when trying setUserSession. Details: ${err.message}`
                );
                return of(null);
            }),
            concatMap((response: any) => {
                if (response) {
                    this.userService.browserClientData = {
                        ip: response.ip,
                        createdAt: new Date(),
                        userAgent: navigator.userAgent,
                    };
                    return this.realtime.database
                        .ref(`portal/user-sessions/${userId}`)
                        .set({
                            ...this.userService.browserClientData,
                            createdAt: moment(new Date()).format(
                                'DD-MM-YYYY HH:mm:ss'
                            ),
                        })
                        .catch((err) => {
                            this.fireLoggingService.sendAlertLog(
                                `Error when trying setUserSession. Details: ${err.message}`
                            );
                        });
                }
                // Ignore session and return an empty response
                return of(true);
            })
        );
    }
}
