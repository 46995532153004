import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { STATUS_PENDENT } from '../../../app.constants';
import { CockpitService } from '../../../modules/company/cockpit/cockpit.service';
import { SideMenuService } from './../../services/side-menu.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-company-side-menu',
    templateUrl: './company-side-menu.component.html',
    styleUrls: ['./company-side-menu.component.scss']
})
export class CompanySideMenuComponent extends BaseComponent implements OnInit, OnDestroy {

    public subscription: Subscription;

    permissions = {
        payrollBookkeeping: [
            'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping',
            'process#holder#define-booking-key', 'process#standIn#define-booking-key',
            'confirm#holder#define-booking-key', 'confirm#standIn#define-booking-key',
            'inform#holder#define-booking-key', 'inform#standIn#define-booking-key',
            'process#holder#config-payroll-accounting', 'process#standIn#config-payroll-accounting',
            'confirm#holder#config-payroll-accounting', 'confirm#standIn#config-payroll-accounting',
            'process#holder#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'process#standIn#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'inform#holder#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'inform#standIn#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'view#config-payroll-accounting', 'view#define-booking-key',
            'view#receive-booking-suggestions-and-transfer-them-to-the-payroll-system'
        ],
        bookkeepingSuggestions: [
            'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping',
            'process#holder#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'process#standIn#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'inform#holder#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'inform#standIn#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'view#receive-booking-suggestions-and-transfer-them-to-the-payroll-system'
        ],
        bookkeepingKeys: [
            'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping',
            'process#holder#define-booking-key', 'process#standIn#define-booking-key',
            'confirm#holder#define-booking-key', 'confirm#standIn#define-booking-key',
            'inform#holder#define-booking-key', 'inform#standIn#define-booking-key',
            'view#define-booking-key'
        ],
        companyMasterData: [
            'admin#holder#bookkeeping',
            'admin#standIn#bookkeeping',
            'process#holder#edit-company-master-data', 'process#standIn#edit-company-master-data',
            'confirm#holder#edit-company-master-data', 'confirm#standIn#edit-company-master-data',
            'inform#holder#edit-company-master-data', 'inform#standIn#edit-company-master-data',
            'view#edit-company-master-data'
        ],
        companyUnits: [
            'admin#holder#bookkeeping',
            'admin#standIn#bookkeeping',
            'process#holder#edit-company-master-data', 'process#standIn#edit-company-master-data',
            'confirm#holder#edit-company-master-data', 'confirm#standIn#edit-company-master-data',
            'inform#holder#edit-company-master-data', 'inform#standIn#edit-company-master-data',
            'view#edit-company-master-data',
        ],
        companyBaseData: [
            'admin#holder#bookkeeping',
            'admin#standIn#bookkeeping',
            'process#holder#edit-company-master-data', 'process#standIn#edit-company-master-data',
            'confirm#holder#edit-company-master-data', 'confirm#standIn#edit-company-master-data',
            'inform#holder#edit-company-master-data', 'inform#standIn#edit-company-master-data',
            'manage-admin-for-each-area', 'view#edit-company-master-data',
        ],
        services: ['manage-services-and-templates', 'manage-document-templates', 'manage-services'],
        actionSequence: [
            'manage-admin-for-each-area', 'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping', 'admin#holder#bookkeeping', 'admin#standIn#bookkeeping',
            'admin#holder#human-resource', 'admin#standIn#human-resource'
        ],
        companyUsers: ['client-user-read'],
        optins: [
            'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping',
            'process#holder#deliver-employee-master-data-to-finstein', 'process#standIn#deliver-employee-master-data-to-finstein',
            'inform#holder#deliver-employee-master-data-to-finstein', 'inform#standIn#deliver-employee-master-data-to-finstein',
            'process#holder#verify-employee-advice-requests', 'process#standIn#verify-employee-advice-requests',
            'inform#holder#verify-employee-advice-requests', 'inform#standIn#verify-employee-advice-requests',
            'process#holder#change-employee-master-data',
            'process#standIn#change-employee-master-data',
            'inform#holder#change-employee-master-data',
            'inform#standIn#change-employee-master-data',
            'process#holder#sign-additions-to-employment-contracts',
            'process#standIn#sign-additions-to-employment-contracts',
            'inform#holder#sign-additions-to-employment-contracts',
            'inform#standIn#sign-additions-to-employment-contracts',
            'process#holder#release-employee-for-salary-increase',
            'process#standIn#release-employee-for-salary-increase',
            'inform#holder#release-employee-for-salary-increase',
            'inform#standIn#release-employee-for-salary-increase',
            'confirm#holder#release-employee-for-salary-increase',
            'confirm#standIn#release-employee-for-salary-increase',
            'view#change-employee-master-data',
            'view#release-optin-to-consultancy',
            'view#deliver-employee-master-data-to-finstein',
            'view#register-optimized-gross-salary',
            'view#sign-additions-to-employment-contracts',
        ],
        employees: [
            'admin#holder#human-resource',
            'admin#standIn#human-resource',
            'process#holder#change-employee-master-data',
            'process#standIn#change-employee-master-data',
            'inform#holder#change-employee-master-data',
            'inform#standIn#change-employee-master-data',
            'view#change-employee-master-data',
            'process#holder#sign-additions-to-employment-contracts',
            'process#standIn#sign-additions-to-employment-contracts',
            'inform#holder#sign-additions-to-employment-contracts',
            'inform#standIn#sign-additions-to-employment-contracts',
            'process#holder#release-employee-for-salary-increase',
            'process#standIn#release-employee-for-salary-increase',
            'inform#holder#release-employee-for-salary-increase',
            'inform#standIn#release-employee-for-salary-increase',
            'confirm#holder#release-employee-for-salary-increase',
            'confirm#standIn#release-employee-for-salary-increase',
        ],
        hardware: ['inform#holder#activation-of-hardware']
    };

    qtyTasksToDo: number;

    constructor(private cockpitService: CockpitService,
                private sideMenu: SideMenuService ) {
        super();
    }

    get loggedUser() {
        return this.userService.loggedUser;
    }

    ngOnInit() {
        super.ngOnInit();
        this.subscription = this.cockpitService.qtyTasksToDo.subscribe(qty => {
            this.qtyTasksToDo = qty;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getCompanyId() {
        return this.userService.loggedUser.companyId;
    }

    getCompanyInitials() {
        const split = this.loggedUser.companyName.split(' ');
        let initials = '';
        for (const part of split) {
            if (part) {
                initials += part.charAt(0).toUpperCase();
            }
        }
        return initials;
    }

    isPendentCompany() {
        return this.userService.loggedUser.companyStatus === STATUS_PENDENT;
    }

    toggleSidebar() {
        this.sideMenu.toggleSidebar();
    }

    activeSidebar() {
        this.sideMenu.toggleSidebarPin();
    }

    isCollapsed() {
        return this.sideMenu.opened;
    }

    checkOnlyAdminPermission(onlyAdmin: boolean): boolean {
        return this.userService.checkOnlyAdminPermission(onlyAdmin) ||
         this.userService.isFinsteinHelper();
    }

    getScheduleSupportAppointmentLink() {
        return `${environment.scheduleSupportAppointmentLink}/saskia-soezeri`;
    }

    enableScheduleSupport() {
        return environment.env === 'prod';
    }
}
