import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { catchError, map, tap } from 'rxjs/operators';
import { ButtonAction } from 'src/app/shared/components/datatable-utils/components/buttons-column/buttons-column.component';
import {
    EMPLOYEES_COLLECTION,
    OP_EQUALS,
    OP_IN,
    REMOVE_OPTIN_FUNCTION,
    SEND_EMPLOYEE_TO_CONSULTANCY_FUNCTION,
    STATUS_CONSULTATION_APPROVAL_PENDING, STATUS_CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS,
    STATUS_DATA_EXPORT_PENDING,
    STATUS_DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS,
    STATUS_DATA_MISSING,
    STATUS_INACTIVE,
} from '../../../../../app.constants';
import { BaseListComponent } from '../../../../../core/abstractions/base-list.component';
import { EmployeeStatusEnum } from '../../../../../shared/enums/employee-status.enum';
import { FilterModel } from '../../../../../shared/models/filter.model';
import { OptinsService } from '../../optins.service';

@Component({
    selector: 'app-optins-list-data-completed',
    templateUrl: './optins-list-data-completed.component.html',
    styleUrls: [ './optins-list-data-completed.component.scss' ],
})
export class OptinsListDataCompletedComponent
    extends BaseListComponent
    implements OnInit, OnDestroy {
    @ViewChild('optinCompletedList') table: DatatableComponent;

    @Input() isViewMode = false;

    buttons: Array<ButtonAction> = [
        {
            icon: 'visibility',
            onClick: (item) => this.navigateToUpdate(item),
            tooltip: 'tooltips.view',
        },
        {
            icon: 'delete',
            onClick: (item) => this.removeOptin(item),
            isDisabled: (item) =>
                (item.statusDescription &&
                    item.statusDescription === 'EMPLOYEE_AND_EMAIL_KNOW') ||
                this.isViewMode,
            tooltip: 'tooltips.delete',
            confirmActionBeforeClick: () => true,
            getConfirmActionText: (item: any) =>
                item.status !== STATUS_INACTIVE
                    ? 'operations.disable.confirmation'
                    : 'operations.enable.confirmation',
        },
    ];

    constructor(private optinService: OptinsService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    getFilter() {
        const filter = new FilterModel();
        filter.clauses = [
            {
                fieldPath: 'status',
                opStr: OP_IN,
                value: [ EmployeeStatusEnum.CONSULTATION_APPROVAL_PENDING ],
            },
            {
                fieldPath: 'companyId',
                opStr: OP_EQUALS,
                value: this.userService.companyId,
            },
        ];
        return filter;
    }

    getCollectionURL() {
        return EMPLOYEES_COLLECTION;
    }

    getRouterURL() {
        return 'optins';
    }

    getStatusValue(employee: any) {
        if (employee.inProcessOfReturningConsultancy) {
            if (employee.status === STATUS_DATA_EXPORT_PENDING) {
                return STATUS_DATA_EXPORT_PENDING_FOR_NEW_CONSULTANCY_PROCESS;
            }
            if (employee.status === STATUS_CONSULTATION_APPROVAL_PENDING) {
                return STATUS_CONSULTATION_APPROVAL_PENDING_FOR_NEW_CONSULTANCY_PROCESS;
            }
        }
        return employee.status;
    }

    removeOptin(item) {
        this.spinner.show();
        this.optinService
            .removeOptin(item.id)
            .pipe(
                map((data: { message: string }) => data.message),
                tap(() => this.spinner.hide()),
                catchError(() => this.spinner.hide())
            )
            .subscribe(
                () => this.notification.disableSuccess(),
                (err) => {
                    if (err) {
                        this.notification.error(err);
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${ REMOVE_OPTIN_FUNCTION }], details: ${ err }`
                        );
                    } else {
                        this.notification.error(
                            `An error occurred while updating optin status`
                        );
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while disabling optin with function [${ REMOVE_OPTIN_FUNCTION }]`
                        );
                    }
                }
            );
    }

    hasAnyImportedOptinSelected() {
        return this.itemsSelected.length > 0;
    }

    navigateToDetails(item: any) {
        if (item.status === STATUS_DATA_MISSING) {
            this.navigateToUpdate(item);
        } else {
            this.navigate([ this.getRouterURL(), 'details', item.id ]);
        }
    }

    navigateToUpdate(item: any) {
        const isHelper = this.userService.isFinsteinHelper();
        const statusIsForEnableFormToHelper = [
            EmployeeStatusEnum.VERIFICATION_PENDING,
            EmployeeStatusEnum.DATA_EXPORT_PENDING,
            EmployeeStatusEnum.NOT_ELIGIBLE_FOR_OPTIMIZATION,
            EmployeeStatusEnum.DATA_MISSING,
            EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE,
            EmployeeStatusEnum.PENDING_OPTIN,
        ].includes(item?.status);

        if (isHelper && statusIsForEnableFormToHelper) {
            this.navigate([this.getRouterURL(), 'update', item.id]);
        } else {
            this.navigate([this.getRouterURL(), 'details', item.id]);
        }
    }

    sendToDataExport() {
        this.spinner.show();
        if (this.itemsSelected?.length) {
            const employeesToUpdate = this.itemsSelected.map(
                (optin) => optin.id
            );
            this.optinService
                .sendOptinToDataExport(employeesToUpdate)
                .subscribe(
                    () => {
                        this.notification.success(
                            'messages.success-send-optins-to-data-export'
                        );
                        this.spinner.hide();
                    },
                    () => {
                        this.notification.error(
                            'messages.error-send-optins-to-data-export'
                        );
                        this.spinner.hide();
                    }
                );
        }
    }

    sendOptinsToConsultancy() {
        this.spinner.show();
        if (this.itemsSelected?.length) {
            const idList = this.itemsSelected.map((item) => item.id);
            this.service
                .callFunction(idList, SEND_EMPLOYEE_TO_CONSULTANCY_FUNCTION, {
                    type: 'callable',
                    httpMethod: 'POST',
                })
                .subscribe({
                    next: (res) => {
                        this.ngZone.run(() => {
                            this.spinner.hide();
                            if (res.error || res.total || res.success) {
                                if (res.error === res.total) {
                                    this.notification.error(
                                        'messages.error-records-not-processed'
                                    );
                                } else if (res.success === res.total) {
                                    this.notification.success(
                                        'messages.success-send-optins-to-consultancy'
                                    );
                                } else {
                                    this.notification.info(
                                        'messages.info-only-some-records-processed',
                                        {
                                            total: res.total,
                                            success: res.success,
                                            error: res.error,
                                        }
                                    );
                                }
                            } else {
                                this.notification.success(
                                    'messages.success-send-optins-to-consultancy'
                                );
                            }
                            this.itemsSelected = [];
                        });
                    },
                    error: (err) => {
                        this.ngZone.run(() => {
                            this.spinner.hide();
                            this.notification.error(
                                'messages.error-send-optins-to-consultancy'
                            );
                            if (err.message) {
                                this.fireLogginService.sendErrorLog(
                                    `An error occurred while sending optins, details: ${ err.message }`
                                );
                            } else {
                                this.fireLogginService.sendErrorLog(
                                    `An error occurred while sending optins with function [${ SEND_EMPLOYEE_TO_CONSULTANCY_FUNCTION }], details: ${ JSON.stringify(
                                        err
                                    ) }`
                                );
                            }
                        });
                    }
                });
        }
    }

    isDisableCheckbox(item: any) {
        return item.status !== EmployeeStatusEnum.CONSULTATION_APPROVAL_PENDING;
    }

    changeStatus(item) {
        super.changeStatus(item);
    }

    canShowErrorReport(item) {
        return (
            item.errorReport &&
            item.status === EmployeeStatusEnum.NOT_ELIGIBLE_FOR_OPTIMIZATION
        );
    }

    isUserAbleToMoveEmployee(): boolean {
        return !this.isViewMode || this.userService?.loggedUser?.finsteinHelper;
    }

    protected readonly SelectionType = SelectionType;
}
