<finstein-base-modal [title]="'tooltips.check-problems-in-optin' | translate" [showActions]="false" actionPosition="end">
	<finstein-circle-warn header></finstein-circle-warn>
	<div content>
		<div class="modal-container" content>
			<ul class="pl-2">
				<li *ngFor="let item of list" class="list">
					<label class="d-flex align-items-start" translate
						#popoverHook="ngbPopover"
						[ngbPopover]="errorReport"
						[closeDelay]="0"
						triggers="mouseenter"
                        (mouseenter)="onHover(item)"
						container="body"
						placement="top"
						[popoverClass]="getPopoverClass(item)"
                        autoClose="true"
                        [disablePopover]="disablePopover(item)"
					>
						<span class="material-icons danger-icon mr-2">cancel</span>
						{{ item }}
					</label>

					<ng-template #errorReport>
						<div class="not-eligible-popover-container">
								<div class="popover-header pl-4">
									<span class="material-icons white">info</span>
								</div>
								<div class="popover-content">
									<div class="d-block" *ngIf="item === 'messages.employee.data-missing'">
										<p class="font-weight-bold" translate>
											messages.employee.not-eligible.missing-fields-text
										</p>
										<ul class="pl-2">
											<li *ngFor="let missingField of missingFields" class="list">
												<span class="font-italic">
													• {{ ('messages.employee.not-eligible.fields.' + missingField) | translate }}
												</span>
											</li>
										</ul>
									</div>
									<div *ngIf="item !== 'messages.employee.data-missing'">
										<p [innerHTML]="getErrorTitle(item) | translate
										"></p>
										<p [innerHTML]="getErrorText(item) | translate"></p>
										<ng-container [ngTemplateOutlet]="checkIfErrorHasVideo(item)
												? errorHasVideo
												: defaultContactText
										" [ngTemplateOutletContext]="{ row: item }">
										</ng-container>
										<p class="mt-4" [innerHTML]="
											'messages.employee.not-eligible.support-text'
												| translate
										"></p>
									</div>
								</div>
						</div>
					</ng-template>
				</li>
			</ul>
		</div>
	</div>
</finstein-base-modal>

<ng-template #defaultContactText>
	<span [innerHTML]="'messages.employee.not-eligible.contact-text' | translate"></span>
</ng-template>

<ng-template #errorHasVideo let-row="row">
	<div class="error-contact-text">
		<span [innerHTML]="
                'messages.employee.not-eligible.contact-text-with-video'
                    | translate
            "></span>
		<span class="video-button">
			<app-player-button [videoName]="getVideoName(row)" videoGalleryDoc="import-errors"
				bodyClass="mr-2 player-button" width="20px" backgroundColor="#0760ef"></app-player-button>
		</span>
	</div>
</ng-template>
