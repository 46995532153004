import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Action, AngularFirestore, DocumentData, DocumentReference, DocumentSnapshot, } from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap, switchMap, take, } from 'rxjs/operators';
import { CompanyModel } from 'src/app/shared/models/company.model';
import { COMPANIES_COLLECTION, DEFAULT_LANGUAGE, OP_EQUALS, STATUS_INACTIVE, USERS_COLLECTION } from '../../app.constants';
import { RoleModel } from '../../shared/models/role.model';
import { LoggedInUser } from '../../shared/models/user.model';
import { FireLoggingService } from './fire-logging.service';
import { LanguageService } from './language.service';
import * as lodash from 'lodash';
import { UserPortalNotificationsEnum } from 'src/app/shared/enums/user-portal-notifications.enum';
import { FinsteinHelperTypesEnum } from 'src/app/shared/enums/finstein-helper-types.enum';

const getChildValues = async (reference: DocumentReference<DocumentData>) => {
    const value = (await reference.get()).data();
    delete value.createdBy;
    return {...value, id: value.id};
};

const getValueFromDocumentData = (reference: DocumentData) => ({
    ...reference.data(),
    id: reference.id,
});

export interface IBrowserClientData {
    ip: string;
    createdAt: Date;
    userAgent: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public browserClientData: IBrowserClientData;
    public loggedUser?: LoggedInUser;
    public company?: CompanyModel;
    private permissionsSub: Subscription = new Subscription();

    constructor(
        private languageService: LanguageService,
        private firestore: AngularFirestore,
        private ngxPermissions: NgxPermissionsService,
        private afAuth: AngularFireAuth,
        private fireLogging: FireLoggingService
    ) {
    }

    get companyId() {
        return this.loggedUser?.companyId;
    }

    /**
     * Flag used to enable the upload of company import files
     */
    get isDebugImportFileMode() {
        return this.loggedUser.debugImportFileMode;
    }

    isFinsteinHelper() {
        return this.loggedUser?.finsteinHelper &&
         [FinsteinHelperTypesEnum.PORTAL_HELPER, FinsteinHelperTypesEnum.GLOBAL_HELPER].includes(this.loggedUser.finsteinHelperType);
    }

    async updateLanguage(newLang: string) {
        if (!!this.loggedUser && !!this.loggedUser.id) {
            try {
                return await this.firestore
                    .collection(USERS_COLLECTION)
                    .doc(this.loggedUser.id)
                    .set(
                        {
                            lang: newLang,
                        },
                        {merge: true}
                    );
            } catch (error) {
                const message = error.error
                    ? error.error.message
                    : error.message;
                const collection = `${ USERS_COLLECTION }/${ this.loggedUser.id }`;
                this.fireLogging.sendErrorLog(
                    `An error occurred while updating item ${ collection }, details: ${
                        message || JSON.stringify(error)
                    }`
                );
            }
        } else {
            return Promise.reject('User not logged in.');
        }
    }

    getLoggedUserPermissions() {
        const permissions = [];
        for (const role of this.loggedUser.roles) {
            for (const perm of role.permissions) {
                if (!permissions.includes(perm)) {
                    permissions.push(perm);
                }
            }
        }
        return permissions;
    }

    // getStoredLoggedUser() {
    //     return this.storageService.get(USER_LOCAL_STORAGE_KEY);
    // }

    isLogged(): Observable<boolean> {
        return this.afAuth.idTokenResult?.pipe(
            map((res) =>
                !!res &&
                res?.claims?.email?.toLowerCase() ===
                this.loggedUser?.email?.toLowerCase() &&
                this.isNotExpired(res) && 
                this.loggedUser?.verified && this.loggedUser?.status !== STATUS_INACTIVE
            )
        );
    }

    hasAnyAuthority(permissions: string[] | string): boolean {
        if (!this.loggedUser || !this.loggedUser.roles) {
            return false;
        }
        if (!Array.isArray(permissions)) {
            permissions = [ permissions ];
        }

        let userPermissions = [];
        userPermissions = userPermissions.concat(this.loggedUser.permissions);
        if (this.loggedUser.roles) {
            this.loggedUser.roles.forEach((role: RoleModel) => {
                role.permissions.forEach((permission) => {
                    userPermissions.push(permission);
                });
            });
        }
        return permissions.some((authority: string) =>
            userPermissions.includes(authority)
        );
    }

    hasAnyViewAuthority(viewPermission: string | Array<string>): boolean {
        if (!this.loggedUser || !this.loggedUser.permissionsReadOnly) {
            return false;
        }
        if (!Array.isArray(viewPermission)) {
            viewPermission = [ viewPermission ];
        }
        return this.loggedUser.permissionsReadOnly.some((permission) =>
            viewPermission.includes(permission)
        );
    }

    hasAnyInfoAuthority(infoPermission: string): boolean {
        if (!this.loggedUser || !this.loggedUser.permissions) {
            return false;
        }
        return (
            this.loggedUser.permissions.findIndex((value) => {
                return (
                    value === 'inform#holder#' + infoPermission ||
                    value === 'inform#standIn#' + infoPermission
                );
            }) !== -1
        );
    }

    /**
     * Listen for changes in the logged in user.
     */
    listenForChangesAtUser(userId?: string): Observable<LoggedInUser | Error> {
        const id = userId || this.loggedUser.id;
        return this.firestore
            .collection(USERS_COLLECTION)
            .doc(id)
            .get()
            .pipe(
                mergeMap(this.buildUserObj),
                concatMap((user) => this.updateLastAccess(user)),
                concatMap((user) => this.resetInactivityNotificationFlow(user)),
                concatMap((user) => this.listenCompanyPermissions(user)),
                map(({company, user}) =>
                    this.setCurrentUser(company, user)
                ),
                finalize(() => {
                    this.languageService.changeLanguage(
                        this.loggedUser?.lang || DEFAULT_LANGUAGE
                    );
                }),
                catchError((error) => {
                    const message = error.error
                        ? error.error.message
                        : error.message;
                    const collection = `${ USERS_COLLECTION }/${ id }`;
                    this.fireLogging.sendErrorLog(
                        `[listenForChangesAtUser(user.service.ts)] An error occurred while getting item ${ collection } for user ${ userId }, details: ${
                            message || JSON.stringify(error)
                        }`
                    );
                    return of(error);
                })
            );
    }

    listenCompanyPermissions(user: any) {
        if (user.companyId) {
            if (this.permissionsSub) {
                this.permissionsSub.unsubscribe();
                this.permissionsSub = new Subscription();
            }
            const permissionsObs = this.firestore
                .collection(COMPANIES_COLLECTION)
                .doc(user.companyId)
                .snapshotChanges()
                .pipe(
                    map((data: Action<DocumentSnapshot<CompanyModel>>) => {
                        return { id: data.payload.id, ...data.payload.data() };
                    }),
                    map((company: any) => {
                        this.company = company;

                        if (!lodash.isEmpty(company?.permissions)) {
                            user.permissions = [
                                ...this.extractPermissionFromCompany(
                                    company.permissions,
                                    user.id
                                ),
                                ...user.permissions
                            ];
                        }

                        if (!lodash.isEmpty(company?.permissionsReadOnly)) {
                            const permissionsReadOnly = company.permissionsReadOnly;
                            if (permissionsReadOnly) {
                                const index = Object.keys(permissionsReadOnly).findIndex(
                                    (value) => value === user.id
                                );
                                if (index !== -1) {
                                    user.permissionsReadOnly = permissionsReadOnly[user.id];
                                }
                            }

                        }

                        user.company = company;
                        if (user.finsteinHelper &&
                             [FinsteinHelperTypesEnum.PORTAL_HELPER, FinsteinHelperTypesEnum.GLOBAL_HELPER].includes(this.loggedUser.finsteinHelperType)) {
                            this.addFinsteinHelperPermissions(user);
                        }
                        return {user, company};
                    }),
                    catchError((error) => {
                        const message = error.error
                            ? error.error.message
                            : error.message;
                        const collection = `${ COMPANIES_COLLECTION }/${ user.companyId }`;
                        this.fireLogging.sendErrorLog(
                            `[listenCompanyPermissions(user.service.ts)] An error occurred while getting item ${ collection }, details: ${
                                message || JSON.stringify(error)
                            }`
                        );
                        return of({user, company: undefined});
                    })
                );

            this.permissionsSub.add(
                permissionsObs.subscribe(() => {
                    this.ngxPermissions.loadPermissions([
                        ...user.permissions,
                        ...user.permissionsReadOnly,
                    ]);
                })
            );

            return permissionsObs;
        } else if (user.finsteinUser) {
            user.roles.forEach((role) =>
                user.permissions.push(...role.permissions)
            );
            this.ngxPermissions.loadPermissions(user.permissions);
        }
        return of({user, company: undefined});
    }

    private addFinsteinHelperPermissions(user: any) {
        user.permissionsReadOnly = [
            'view#define-booking-key',
            'view#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'view#verify-employee-advice-requests',
            'view#deliver-employee-master-data-to-finstein',
            'view#edit-company-master-data',
            'view#activation-of-hardware',
            'view#view-invoices-from-finstein',
            'view#paperless-booking',
            'view#reimbursement-of-expenses',
            'view#sign-additions-to-employment-contracts',
            'view#release-additional-net-potential-for-services',
            'view#voluntary-special-payments-for-optimization-record',
            'view#record-new-hires',
            'view#release-employee-for-salary-increase',
            'view#change-employee-master-data',
        ];
        user.permissions = [
            'inform#holder#release-employee-for-salary-increase',
            'inform#holder#deliver-employee-master-data-to-finstein',
            'inform#holder#define-booking-key',
            'inform#holder#verify-employee-advice-requests',
            'inform#holder#config-payroll-accounting',
            'inform#holder#edit-company-master-data',
            'inform#holder#change-employee-master-data',
            'inform#holder#release-optin-to-consultancy',
            'inform#holder#receive-booking-suggestions-and-transfer-them-to-the-payroll-system',
            'inform#holder#sign-additions-to-employment-contracts',
            'manage-admin-for-each-area',
            'manage-granting-viewing-rights',
            'manage-services',
        ];
    }

    checkOnlyAdminPermission(onlyAdmin: boolean): boolean {
        if (onlyAdmin) {
            return (
                !!this.loggedUser.companyResponsible ||
                !!this.loggedUser.finsteinUser
            );
        }
        return true;
    }

    /* Removes data of current user */
    public reset() {
        this.company = null;
        this.permissionsSub?.unsubscribe();
        this.loggedUser = new LoggedInUser();
        this.browserClientData = null;
        this.ngxPermissions.flushPermissions();
    }

    private updateLastAccess(user: LoggedInUser): Observable<any> {
        if (user && !user.finsteinUser && !!user.companyId) {
            return from(
                this.firestore
                    .collection(USERS_COLLECTION)
                    .doc(user.id)
                    .set(
                        {
                            lastAccess: Timestamp.now(),
                            inactivitySettings: null,
                        },
                        {merge: true}
                    )
                    .then(() => user)
                    .catch((error) => {
                        const message = error.error
                            ? error.error.message
                            : error.message;
                        const path = `${ COMPANIES_COLLECTION }/${ this.loggedUser.companyId }`;
                        this.fireLogging.sendErrorLog(
                            `[updateLastAccess(user.service.ts)] An error occurred while updating the item ${ path }, details: ${
                                message || JSON.stringify(error)
                            }`
                        );
                        return of(false);
                    })
            );
        } else {
            return of(user);
        }
    }

    private resetInactivityNotificationFlow(user?: LoggedInUser) {
        if (user?.inactivitySettings?.status === 'DONE') {
            user.inactivitySettings = null;
            return this.firestore
                .collection(USERS_COLLECTION)
                .doc(user.id)
                .set({inactivitySettings: null}, {merge: true})
                .then(() => user)
                .catch((error) => {
                    const message = error.error
                        ? error.error.message
                        : error.message;
                    const path = `${ USERS_COLLECTION }/${ user.id }`;
                    this.fireLogging.sendErrorLog(
                        `[resetInactivityNotificationFlow(user.service.ts)] An error occurred while updating the item ${ path }, details: ${
                            message || JSON.stringify(error)
                        }`
                    );
                    return user;
                });
        }
        return of(user);
    }

    private findUserCompany(user: LoggedInUser): Observable<any> {
        if (!user.finsteinUser && !!user.companyId) {
            return this.firestore
                .collection(COMPANIES_COLLECTION)
                .doc(user.companyId)
                .get()
                .pipe(
                    map(getValueFromDocumentData),
                    map((company) => ({company, user})),
                    catchError((error) => {
                        const message = error.error
                            ? error.error.message
                            : error.message;
                        const collection = `${ COMPANIES_COLLECTION }/${ user.companyId }`;
                        this.fireLogging.sendErrorLog(
                            `[findUserCompany(user.service.ts)] An error occurred while getting item ${ collection }, details: ${
                                message || JSON.stringify(error)
                            }`
                        );
                        return of(false);
                    })
                );
        } else {
            return of({company: undefined, user});
        }
    }

    getAllUsersInCompany(companyId: string) {
        return this.firestore
            .collection(USERS_COLLECTION, (ref) => {
                return ref
                    .where('companyId', OP_EQUALS, companyId);
            })
            .get()
            .pipe(
                map((res) => {
                    return res.docs.map(doc => {
                        const data: any = doc.data();
                        return {
                            id: doc.id,
                            ...data
                        };
                    });
                })
            );
    }

    /**
     * Map the reference of sub-collections to the user (sub-collections: roles, subscription list...)
     * @param userData The user retrieved
     */
    async buildUserObj(value: DocumentData): Promise<LoggedInUser> {
        const userData = value.data();
        const user = new LoggedInUser();
        user.id = value.id;
        user.finsteinUser = userData.finsteinUser;
        user.finsteinHelper = userData.finsteinHelper;
        user.finsteinHelperType = userData.finsteinHelperType;
        user.firstName = userData.firstName;
        user.lastName = userData.lastName;
        user.email = userData.email;
        user.lang = userData.lang;
        user.verified = userData.verified;
        user.companyId = userData.companyId;
        user.permissions = userData.permissions || [];
        user.status = userData.status;
        user.companyResponsible = userData.companyResponsible;
        user.phone = userData.phone;
        user.inactivitySettings = userData.inactivitySettings;
        user.portalNotifications = userData.portalNotifications;

        if (userData.roles) {
            await Promise.all(userData.roles.map(getChildValues)).then(
                (roleList) => (user.roles = roleList)
            );
        }
        if (userData.notificationSubscriptions) {
            await Promise.all(
                userData.notificationSubscriptions.map(getChildValues)
            ).then(
                (subscriptionList) =>
                    (user.notificationSubscriptions = subscriptionList)
            );
        }

        return user;
    }

    public getById(id: string, path: string): Observable<any> {
        return this.firestore
            .collection(path)
            .doc(id)
            .valueChanges()
            .pipe(
                map((entity: any) => {
                    return entity;
                }),
                catchError((error) => {
                    const message = error.error
                        ? error.error.message
                        : error.message;
                    this.fireLogging.sendErrorLog(
                        `[getById(user.service.ts)] An error occurred while getting item ${ path }/${ id }, details: ${
                            message || JSON.stringify(error)
                        }`
                    );
                    return of(false);
                })
            );
    }

    private extractPermissionFromCompany(permissions: any, userId: string) {
        if (permissions) {
            return Object.keys(permissions).filter(
                (key) => permissions[key] === userId
            );
        }
        return [];
    }

    loadCurrentUser(): Observable<LoggedInUser | any> {
        if (!this.loggedUser) {
            return from(this.afAuth.idTokenResult).pipe(
                switchMap((token) => {
                    if (token?.claims && token?.claims['user_id']) {
                        return this.listenForChangesAtUser(token.claims['user_id']).pipe(take(1));
                    } else {
                        return of(false);
                    }
                })
            );
        }
        return of(this.loggedUser);
    }

    private setCurrentUser(
        company: CompanyModel,
        user: LoggedInUser,
    ) {
        user.defaultPermissions = [ ...user.permissions ];
        if (company) {
            user.companyName = company.name;
            user.companyId = company.id;
            user.companyConfig = company.configData;
            user.isDemo = company.isDemo;
            user.debugImportFileMode = company.debugImportFileMode;
        }
        this.loggedUser = user;
        return this.loggedUser;
    }

    private isNotExpired({expirationTime}) {
        return new Date(expirationTime) > new Date();
    }

    updateUserNotifications(notification: UserPortalNotificationsEnum) {
        const portalNotifications = this.loggedUser?.portalNotifications ?? {};
        portalNotifications[notification] = true;

        return this.firestore
        .collection(USERS_COLLECTION)
        .doc(this.loggedUser?.id)
        .update(
            {
                portalNotifications,
            },
        )
        .catch((error) => {
            const message = error.error
                ? error.error.message
                : error.message;
            const path = `${ USERS_COLLECTION }/${ this.loggedUser.id }`;
            this.fireLogging.sendErrorLog(
                `[updateUserNotifications(user.service.ts)] An error occurred while updating the item ${ path }, details: ${
                    message || JSON.stringify(error)
                }`
            );
            return of({ error });
        })
    }
}
