import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { CustomValidators } from 'src/app/core/util/custom-validators';
import {
    isNumberTypeKeySystem,
    isStringTypeKeySystem
} from 'src/app/shared/enums/accounting-systems.enum';
import { needToFillTheField } from 'src/app/utils/form.utils';
import {
    ADDRESSES_COLLECTION,
    ADVANCED_COLLECTION,
    EMPLOYEES_COLLECTION,
    EMPLOYEE_MASTER_DATA_COLLECTION,
    GENDERS,
    GENERAL_COLLECTION,
    IMPORT_EMPLOYEE_DATA_FUNCTION,
    SERVICE_RETIREMENT,
    STATES,
    STATUS_DATA_MISSING,
    UPDATE_OPTIN_PERSONAL_DATA_FUNCTION,
    WEEKLY_WORKING_HOURS_COLLECTION
} from '../../../../app.constants';
import { BaseEditComponent } from '../../../../core/abstractions/base-edit.component';
import { FunctionOptions } from '../../../../core/domain/function-options';
import { EmployeeService } from '../../../../core/services/employee.service';
import { InputMaskService } from '../../../../core/services/input-mask.service';
import { EmployeeStatusEnum } from '../../../../shared/enums/employee-status.enum';
import { ChangeHistoryMappedModel } from '../../../../shared/models/change-history.model';
import { OptinsService } from '../optins.service';

@Component({
    templateUrl: './optin-form.component.html',
    styleUrls: ['./optin-form.component.scss']
})
export class OptinFormComponent extends BaseEditComponent implements OnInit, OnDestroy {

    now = new Date();
    wageDataList = [];
    formLoaded = false;
    subscriptions: Subscription = new Subscription();
    showOnlyPersonData = false;
    showEditPersonData = false;
    personalDataEnabled = false;
    originalPersonalDataForm: any;
    forceViewMode: boolean;

    constructor(private route: ActivatedRoute,
                private employeeService: EmployeeService,
                private serviceOptin: OptinsService,
                public inputMask: InputMaskService) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.route.data.subscribe(({isViewMode}) => {
            this.forceViewMode = isViewMode;
        });
    }

    protected setIsViewMode() {
        const isHelper = this.userService.isFinsteinHelper();
        if (isHelper && !this.forceViewMode) {
            const statusIsForEnableFormToHelper = [
                EmployeeStatusEnum.VERIFICATION_PENDING,
                EmployeeStatusEnum.DATA_EXPORT_PENDING,
                EmployeeStatusEnum.NOT_ELIGIBLE_FOR_OPTIMIZATION,
                EmployeeStatusEnum.DATA_MISSING,
                EmployeeStatusEnum.DATA_EXPORT_INCOMPLETE,
                EmployeeStatusEnum.PENDING_OPTIN,
            ].includes(this.item?.status);
            if (statusIsForEnableFormToHelper) {
                this.editForm?.enable();
            }
            this.isViewMode = !statusIsForEnableFormToHelper;
        } else if (this.hasAuthorityToEdit() && this.showOnlyPersonData) {
            this.isViewMode = false;
        } else if (this.hasAuthorityToEdit()) {
            // Block edit optin data for other tabs
            this.isViewMode = true;
        } else {
            this.isViewMode = this.forceViewMode;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        super.ngOnDestroy();
    }

    get genders() {
        return GENDERS;
    }

    get wageDataFormArray() {
        return this.editForm.get('importedData').get('wageData') as UntypedFormArray;
    }

    get wageDataSummaryFormArray() {
        return this.editForm.get('importedData').get('wageDataSummary') as UntypedFormArray;
    }

    getCollectionURL(): string {
        return EMPLOYEES_COLLECTION;
    }

    getRouterURL(): string {
        return 'optins';
    }

    getServiceIdByKey(serviceKey: number) {
        for (const service of this.wageDataList) {
            if (
                service.code.includes(
                    isStringTypeKeySystem(this.importSystemType)
                        ? serviceKey
                        : Number(serviceKey)
                )
            ) {
                return service.id;
            }
        }
    }

    addWageData(wage?: any) {
        const serviceKeyValidators = [Validators.required];
        if (isNumberTypeKeySystem(this.importSystemType)) {
            serviceKeyValidators.push(CustomValidators.onlyNumbers);
        }
        this.wageDataFormArray.push(new UntypedFormGroup({
            serviceKey: new UntypedFormControl(wage?.serviceKey, serviceKeyValidators),
            amount: new UntypedFormControl(wage?.amount, [
                Validators.required,
                CustomValidators.validate(
                    CustomValidators.onlyPositiveNumbers()
                ).if((control: AbstractControl) => {
                    const serviceKey =
                        control?.parent?.get('serviceKey')?.value ?? '';
                    const serviceId = this.getServiceIdByKey(serviceKey);
                    return serviceId !== 'retirement';
                }),
            ]),
            costCentre: new UntypedFormControl(wage?.costCentre, []),
            isBlocked: new UntypedFormControl(wage?.isBlocked ?? false, []),
            isBlockedBy: new UntypedFormControl(wage?.isBlockedBy, []),
            isFromWageHistory: new UntypedFormControl(wage?.isFromWageHistory, []),
            onePayment: new UntypedFormControl(wage?.onePayment, []),
            originalAmount: new UntypedFormControl(wage?.originalAmount, []),
            amountMultiplier: new UntypedFormControl(wage?.amountMultiplier, []),
            billingMonth: new UntypedFormControl(wage?.billingMonth, []),
        }));
    }

    addWageDataSummary(wage?: any) {
        const serviceKeyValidators = [Validators.required];
        if (isNumberTypeKeySystem(this.importSystemType)) {
            serviceKeyValidators.push(CustomValidators.onlyNumbers);
        }
        this.wageDataSummaryFormArray.push(new UntypedFormGroup({
            serviceKey: new UntypedFormControl(wage?.serviceKey, serviceKeyValidators),
            amount: new UntypedFormControl(wage?.amount, [ Validators.required ]),
            amountMultiplier: new UntypedFormControl(wage?.amountMultiplier, [ Validators.required ]),
            onePayment: new UntypedFormControl(wage?.onePayment, []),
        }));
    }

    removeWageData(index: number, control: AbstractControl) {
        if (this.item.wageHistoryData?.length) {
            const wage = control.value;
            const wageHistoryIndex = this.item.wageHistoryData.findIndex(
                (wageHistory) => wage.serviceKey === wageHistory.wageTypeNumber
                    && wage.amount === wageHistory.amount
                    && wage.costCentre === wageHistory.masterWageTypeNumber,
            );
            if (wageHistoryIndex !== -1) {
                this.item.wageHistoryData.splice(wageHistoryIndex, 1);
            }
        }
        this.wageDataFormArray.removeAt(index);
    }

    getFormControls(): object {
        const importedDataControl = {
            gender: new UntypedFormControl(undefined, [Validators.required]),
            registration: new UntypedFormControl(undefined, [Validators.required]),
            addressStreet: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            addressNumber: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            addressAdditional: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            addressState: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            city: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            country: new UntypedFormControl(undefined, [CustomValidators.noWhitespaceValidator]),
            postalCode: new UntypedFormControl(),
            entryDate: new UntypedFormControl(undefined, [Validators.required,
                CustomValidators.dateLowerThanOtherDate('leavingDate')
            ]),
            leavingDate: new UntypedFormControl(undefined, [
                CustomValidators.dateGreaterThanOtherDate('entryDate')
            ]),
            payAs: new UntypedFormControl(undefined),
            healthInsuranceContribution: new UntypedFormControl(undefined, [Validators.required]),
            careContribution: new UntypedFormControl(undefined, [Validators.required]),
            healthInsuranceBasicContribution: new UntypedFormControl(),
            taxClass: new UntypedFormControl(undefined, [Validators.required]),
            taxFactor: new UntypedFormControl(),
            children: new UntypedFormControl(undefined, [Validators.required]),
            religion: new UntypedFormControl(undefined, [Validators.required]),
            annualTaxAllowance: new UntypedFormControl(undefined, [Validators.required]),
            healthInsuranceNumber: new UntypedFormControl(undefined, [Validators.required]),
            healthInsuranceNumberPercent: new UntypedFormControl(),
            additionalHealthInsuranceContribution: new UntypedFormControl(),
            additionalHealthInsuranceContributionRate: new UntypedFormControl(),
            careInsuranceContribution: new UntypedFormControl(undefined, [Validators.required]),
            contributionGroupKey: new UntypedFormControl(undefined, [Validators.required]),
            personalGroupKey: new UntypedFormControl(undefined, []),
            wageData: new UntypedFormArray([], [Validators.required]),
            wageDataSummary: new UntypedFormArray([], [Validators.required]),
            regularDailyWorkingHours: new UntypedFormControl(undefined, []),
            workingDaysPerWeek: new UntypedFormControl(undefined, []),
            weeklyWorkingHours: new UntypedFormControl({value: '', disabled: true}, []),
            type: new UntypedFormControl(undefined, [])
        };

        importedDataControl.regularDailyWorkingHours.valueChanges.pipe(
            this.updateWeeklyWorkingHours(importedDataControl.workingDaysPerWeek)
        ).subscribe();
        importedDataControl.workingDaysPerWeek.valueChanges.pipe(
            this.updateWeeklyWorkingHours(importedDataControl.regularDailyWorkingHours)
        ).subscribe();

        return {
            firstName: new UntypedFormControl({value: '', disabled: true}, [Validators.required, CustomValidators.noWhitespaceValidator]),
            lastName: new UntypedFormControl({value: '', disabled: true}, [Validators.required, CustomValidators.noWhitespaceValidator]),
            email: new UntypedFormControl({value: '', disabled: true}, [
                Validators.required,
                CustomValidators.email,
                CustomValidators.noWhitespaceValidator
            ]),
            phone: new UntypedFormControl({value: '', disabled: true}, [Validators.required, CustomValidators.phone]),
            birthday: new UntypedFormControl({value: '', disabled: true}, [Validators.required]),
            importedData: this.formBuilder.group(importedDataControl)
        };
    }

    getActivatedRoute(): ActivatedRoute {
        return this.route;
    }

    protected getFormValue() {
        const form = this.editForm.getRawValue();
        if (form.birthday) {
            form.birthday = moment(form.birthday).format('DD.MM.YYYY');
        }
        if (this.showOnlyPersonData) {
            return {
                id: this.item.id,
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                phone: form.phone,
                birthday: form.birthday,
            };
        } else {
            form.importedData.entryDate = moment(form.importedData.entryDate).format('DD.MM.YYYY');
            if (form.importedData.leavingDate) {
                form.importedData.leavingDate = moment(form.importedData.leavingDate).format('DD.MM.YYYY');
            }
            if (form.importedData.wageData && form.importedData.wageData.length) {
                form.importedData.wageData = form.importedData.wageData.map((wage: any) => {
                    delete wage.serviceName;
                    wage.amount = Number(wage.amount);
                    wage.costCentre = Number(wage.costCentre);
                    wage.serviceKey = isNumberTypeKeySystem(this.importSystemType) ? Number(wage.serviceKey) : wage.serviceKey ;
                    return wage;
                });
            }
            form.importedData.wageHistoryData = this.item.importedData.wageHistoryData;
            return {
                ...form.importedData,
                id: this.item.id,
                firstName: form.importedData.firstName,
                lastName: form.importedData.lastName,
                email: form.importedData.email,
                phone: form.importedData.phone,
                birthday: form.importedData.birthday,
            };
        }

    }

    postGetItem() {
        this.spinner.show();
        this.setIsViewMode();
        this.fetchChangeHistory();
        this.employeeService.getImportedData(this.item.id).pipe(take(1)).subscribe((data) => {
            this.item.importedData = data;
            this.item.birthday = moment(this.item.birthday, 'DD.MM.YYYY').toDate();
            this.originalPersonalDataForm = {
                firstName: this.item.firstName,
                lastName: this.item.lastName,
                phone: this.item.phone,
                birthday: moment(this.item.birthday, 'DD.MM.YYYY').toDate()
            };
            if (this.item.importedData) {
                if (this.item.importedData.entryDate) {
                    this.item.importedData.entryDate = moment(this.item.importedData.entryDate, 'DD.MM.YYYY').toDate();
                }

                if (this.item.importedData.leavingDate) {
                    this.item.importedData.leavingDate = moment(this.item.importedData.leavingDate, 'DD.MM.YYYY').toDate();
                }
                if (!this.item.importedData.regularDailyWorkingHours && !this.item.importedData.workingDaysPerWeek) {
                    // In datev import both fields are not filled, so the user can inform the value directly
                    this.editForm.get('importedData').get('weeklyWorkingHours').enable();
                    this.editForm.get('importedData').get('regularDailyWorkingHours').disable();
                    this.editForm.get('importedData').get('workingDaysPerWeek').disable();
                }

                if (!this.item.importedData.wageData) {
                    this.item.importedData.wageData = [];
                }

                if (!this.item.importedData.wageDataSummary) {
                    this.item.importedData.wageDataSummary = [];
                }

                if (this.item.status === STATUS_DATA_MISSING) {
                    this.editForm.markAllAsTouched();
                }
                this.editForm.patchValue(this.item);
                this.updateFormArray(this.item.importedData.wageData, this.item.importedData.wageDataSummary);
                this.formLoaded = true;
            } else {
                if (!this.item.importedData) {
                    this.item.importedData = {};
                }
                this.editForm.patchValue(this.item);
                if (this.item.status === EmployeeStatusEnum.VERIFICATION_PENDING) {
                    this.editForm.get('firstName').enable();
                    this.editForm.get('lastName').enable();
                    this.editForm.get('email').enable();
                    this.editForm.get('phone').enable();
                    this.editForm.get('birthday').enable();
                    this.editForm.removeControl('importedData');
                    this.showOnlyPersonData = true;
                    this.setIsViewMode();
                }
                this.formLoaded = true;
            }
            if (!this.userService.isFinsteinHelper()) {

                this.showEditPersonData = true;
            }
            this.setIsViewMode();
            if (this.isViewMode) {
                this.editForm.disable();
            }
            this.fetchAllWageKeys();

            if (this.item.status !== EmployeeStatusEnum.VERIFICATION_PENDING) {
                this.editForm.get('email').disable();
            }
            this.spinner.hide();
        });
    }

    private updateFormArray(wageList, wageSummaryList) {
        if (wageList && wageList.length) {
            this.wageDataFormArray.clear();
            for (const wage of wageList) {
                this.addWageData(wage);
            }
        }
        if (wageSummaryList && wageSummaryList.length) {
            this.wageDataSummaryFormArray.clear();
            for (const summary of wageSummaryList) {
                this.addWageDataSummary(summary);
            }
        }
        this.wageDataSummaryFormArray.disable(); // The summary is just for visualization
        if (this.isViewMode) {
            this.wageDataFormArray.disable();
        }
    }

    protected preInsert(): void {
        this.normalizeDates();
    }

    protected preUpdate(): void {
        this.normalizeDates();
    }

    getFunctionOptions() {
        return new FunctionOptions('callable');
    }

    getUpdateFunctionName(): string {
        return this.showOnlyPersonData ? UPDATE_OPTIN_PERSONAL_DATA_FUNCTION : IMPORT_EMPLOYEE_DATA_FUNCTION;
    }

    getCreateFunctionName(): string {
        return IMPORT_EMPLOYEE_DATA_FUNCTION;
    }

    getImportedDataFormGroup() {
        return this.editForm.get('importedData') as UntypedFormGroup;
    }

    get title() {
        return this.isViewMode ? 'optins.details' : 'optins.update';
    }

    get importSystemType() {
        return this.getImportedDataFormGroup()?.get('type')?.value;
    }

    getServiceName(control: AbstractControl) {
        const serviceKey = control.get('serviceKey').value.toString();
        let onePayment = control.get('onePayment').value;
        const labelName = this.translateService.currentLang === 'de' ? 'nameDe' : 'nameEn';

        for (const service of this.wageDataList) {
            const existService = service.code.includes(serviceKey);
            const existTaxService = service.tax?.code?.includes(serviceKey);

            if (onePayment == null) {
                if (existService) {
                    control.get('onePayment').setValue(service.onePayment);
                    onePayment = service.onePayment;
                } else if (existTaxService) {
                    control.get('onePayment').setValue(service.tax.onePayment);
                    onePayment = service.tax.onePayment;
                }
            }

            let complement;
            if (service.id === SERVICE_RETIREMENT && (existService || existTaxService)) {
                if (
                    service.separatedKeys.regular.companyPart.includes(serviceKey) ||
                    service.separatedKeys.tax.companyPart.includes(serviceKey)
                ) {
                    complement = this.translate('retirement-service-key-modal.company-part');
                } else if (
                    service.separatedKeys.regular.employeePart.includes(serviceKey) ||
                    service.separatedKeys.tax.employeePart.includes(serviceKey)
                ) {
                    complement = this.translate('retirement-service-key-modal.employee-part');
                } else if (
                    service.separatedKeys.regular.total.includes(serviceKey) ||
                    service.separatedKeys.tax.total.includes(serviceKey)
                ) {
                    complement = this.translate('retirement-service-key-modal.total');
                }
            }

            let serviceName;
            if (existService) {
                serviceName = service[labelName];
            } else if (existTaxService) {
                serviceName = service.tax[labelName];
            }
            if (serviceName) {
                if (complement) {
                    serviceName += ` (${complement})`;
                }
                return serviceName;
            }
        }
    }

    private normalizeDates() {
        this.item.birthday = moment(this.item.birthday).format('DD.MM.YYYY');
        this.item.entryDate = moment(this.item.entryDate).format('DD.MM.YYYY');
        this.item.leavingDate = moment(this.item.leavingDate).format('DD.MM.YYYY');
    }

    isPersonalDataFormInvalid() {
        if (!this.formLoaded || this.isViewMode) {
            return false;
        }
        return needToFillTheField(this.editForm, 'firstName') ||
            needToFillTheField(this.editForm, 'lastName') ||
            needToFillTheField(this.editForm, 'email') ||
            needToFillTheField(this.editForm, 'phone') ||
            needToFillTheField(this.editForm, 'birthday');
    }

    isPayrollDataFormInvalid() {
        if (!this.formLoaded || this.isViewMode) {
            return false;
        }

        const importedData = this.editForm.get('importedData');

        return needToFillTheField(importedData, 'healthInsuranceNumber') ||
            needToFillTheField(importedData, 'careInsuranceContribution') ||
            needToFillTheField(importedData, 'healthInsuranceContribution') ||
            needToFillTheField(importedData, 'careContribution') ||
            needToFillTheField(importedData, 'taxClass') ||
            needToFillTheField(importedData, 'children') ||
            needToFillTheField(importedData, 'religion') ||
            needToFillTheField(importedData, 'annualTaxAllowance') ||
            needToFillTheField(importedData, 'contributionGroupKey') ||
            needToFillTheField(importedData, 'wageData');
    }

    isWageDataFormInvalid() {
        if (!this.formLoaded || this.isViewMode) {
            return false;
        }
        return this.wageDataFormArray.invalid;
    }

    get states() {
        return STATES;
    }

    updateWeeklyWorkingHours(anotherField: AbstractControl) {
        return tap((value: number) => {
            if (value && anotherField?.value) {
                this.editForm.get('importedData').get('weeklyWorkingHours').setValue(value * anotherField.value);
                this.editForm.get('importedData').get('weeklyWorkingHours').disable();
            }
        });
    }

    private fetchAllWageKeys() {
        this.subscriptions.add(
            this.serviceOptin
                .fetchAllWageKeys(this.item?.importedData?.type, this.item?.companyId)
                .subscribe((keys) => {
                    this.wageDataList = keys;
                })
        );
    }

    getPersonalFormValue(): any {
        return {
            id: this.item.id,
            firstName: this.editForm.get('firstName').value,
            lastName: this.editForm.get('lastName').value,
            phone: this.editForm.get('phone').value,
            birthday: moment(this.editForm.get('birthday').value).format('DD.MM.YYYY'),
            email: this.editForm.get('email').value
        };
    }

    checkPersonalFormValid(): any {
        return {
            id: this.item.id,
            firstName: this.editForm.get('firstName').valid,
            lastName: this.editForm.get('lastName').valid,
            phone: this.editForm.get('phone').valid,
            birthday: this.editForm.get('birthday').valid
        };
    }

    checkPersonalDataEnable() {
        return this.editForm.get('firstName').enabled
            && this.editForm.get('lastName').enabled
            && this.editForm.get('phone').enabled
            && this.editForm.get('birthday').enabled;
    }

    toggleForm() {
        if (this.checkPersonalDataEnable()) {
            this.editForm.get('firstName').disable();
            this.editForm.get('lastName').disable();
            this.editForm.get('phone').disable();
            this.editForm.get('birthday').disable();
            this.editForm.get('email').disable();
        } else {
            this.editForm.get('firstName').enable();
            this.editForm.get('lastName').enable();
            this.editForm.get('phone').enable();
            this.editForm.get('birthday').enable();
            if (this.item.status === EmployeeStatusEnum.VERIFICATION_PENDING) {
                this.editForm.get('email').enable();
            }
        }
        this.personalDataEnabled = !this.personalDataEnabled;
        if (!this.personalDataEnabled) {
            this.editForm.get('firstName').setValue(this.originalPersonalDataForm.firstName);
            this.editForm.get('lastName').setValue(this.originalPersonalDataForm.lastName);
            this.editForm.get('phone').setValue(this.originalPersonalDataForm.phone);
            this.editForm.get('birthday').setValue(this.originalPersonalDataForm.birthday);
        }
    }


    submitPersonalCompanyData() {
        if (this.checkPersonalFormValid()) {
            const data = this.getPersonalFormValue();
            this.spinner.show();
            this.service.update(data.id, data, UPDATE_OPTIN_PERSONAL_DATA_FUNCTION, this.getFunctionOptions())
                .pipe().subscribe({
                    next: (res) => {
                        this.notification.showToast(res?.code, res?.message);
                        this.spinner.hide();
                        this.toggleForm();
                    },
                    error: (error) => {
                        this.spinner.hide();
                        this.notification.showToast(error?.code, error?.message);
                    }
                });
        }
    }

    hasHistoricalData() {
        return this.item && this.item.historicalData && this.item.historicalData.referenceId === this.item.id;
    }

    hasHistoricalDataForWageData() {
        return this.item &&
            this.item.historicalData &&
            this.item.historicalData.referenceId === this.item.id &&
            this.item.historicalData.data.some(data => ['importedData.wageData'].includes(data.property));
    }

    hasHistoricalDataForPersonalData() {
        return this.item &&
            this.item.historicalData &&
            this.item.historicalData.referenceId === this.item.id &&
            this.item.historicalData.data.some(data => ['firstName', 'lastName', 'email', 'phone', 'birthday'].includes(data.property));
    }

    hasHistoricalDataForAddress() {
        return this.item &&
            this.item.historicalData &&
            this.item.historicalData.referenceId === this.item.id &&
            this.item.historicalData.data.some(data => ['importedData.addressStreet', 'importedData.addressNumber',
                'importedData.addressAdditional', 'importedData.postalCode', 'importedData.city', 'importedData.addressState',
                'importedData.country'].includes(data.property));
    }

    hasHistoricalDataForPayroll() {
        return this.item &&
            this.item.historicalData &&
            this.item.historicalData.referenceId === this.item.id &&
            this.item.historicalData.data.some(data => ['importedData.healthInsuranceNumber', 'importedData.healthInsuranceNumberPercent',
                'importedData.additionalHealthInsuranceContribution', 'importedData.additionalHealthInsuranceContributionRate',
                'importedData.careInsuranceContribution', 'importedData.healthInsuranceContribution',
                'importedData.careContribution', 'importedData.registration', 'importedData.entryDate', 'importedData.leavingDate',
                'importedData.payAs', 'importedData.healthInsuranceBasicContribution', 'importedData.taxClass', 'importedData.taxFactor',
                'importedData.children', 'importedData.religion', 'importedData.currency', 'importedData.contributionGroupKey',
                'importedData.regularDailyWorkingHours', 'importedData.workingDaysPerWeek', 'importedData.weeklyWorkingHours'
            ].includes(data.property));
    }

    protected setChangeHistoryData(changeHistory: ChangeHistoryMappedModel, params?: any) {
        if (changeHistory.collection === EMPLOYEES_COLLECTION) {
            this.item.id = changeHistory.referenceId;
            changeHistory.data.forEach((data) => {
                data.property = `importedData.${data.property}`;
            });
        }
        if (changeHistory.referenceId === GENERAL_COLLECTION) {
            this.item.id = GENERAL_COLLECTION;
            const wageDataList = [];
            changeHistory.data.forEach((data) => {
                data.property = `importedData.${data.property}`;
                if (data.property === 'importedData.wageData') {
                    data.newValue.forEach((newValue, index) => {
                        wageDataList.push({
                            reference: changeHistory.reference,
                            property: `importedData.wageData[${index}].serviceKey`,
                            newValue: newValue.serviceKey,
                            previousValue: data.previousValue && data.previousValue[index] ? data.previousValue[index].serviceKey : null
                        });
                        wageDataList.push({
                            reference: changeHistory.reference,
                            property: `importedData.wageData[${index}].amount`,
                            newValue: newValue.amount,
                            previousValue: data.previousValue && data.previousValue[index] ? data.previousValue[index].amount : null
                        });
                    });
                }
            });
            changeHistory.data.push(...wageDataList);
        }
        if (new RegExp(`^${EMPLOYEES_COLLECTION}/[a-zA-Z0-9]+/${ADVANCED_COLLECTION}/${EMPLOYEE_MASTER_DATA_COLLECTION}/${WEEKLY_WORKING_HOURS_COLLECTION}$`).test(changeHistory.collection)) {
            this.item.id = changeHistory.referenceId;
            changeHistory.data.forEach((data) => {
                if (data.property === 'value') {
                    data.property = 'weeklyWorkingHours';
                }
                data.property = `importedData.${data.property}`;
            });
        }
        if (new RegExp(`^${EMPLOYEES_COLLECTION}/[a-zA-Z0-9]+/${ADDRESSES_COLLECTION}$`).test(changeHistory.collection)) {
            this.item.id = changeHistory.referenceId;
            changeHistory.data.forEach((data) => {
                if (data.property === 'street') {
                    data.property = 'addressStreet';
                }
                if (data.property === 'state') {
                    data.property = 'addressState';
                }
                if (data.property === 'zipCode') {
                    data.property = 'postalCode';
                }
                data.property = `importedData.${data.property}`;
            });
        }
        this.item.historicalData = changeHistory;
    }

    getWageDataCustomProperty(property: string, index: number) {
        return `importedData.wageData[${index}].${property}`;
    }

    get serviceKeyType() {
        return isStringTypeKeySystem(this.importSystemType) ? 'string' : 'number';
    }

    hasAuthorityToEdit() {
        return (
            this.userService.hasAnyAuthority(
                this.getPermissionToAddNewItem()
            )
        );
    }

    getPermissionToAddNewItem() {
        return [
            'admin#holder#payroll-bookkeeping',
            'admin#standIn#payroll-bookkeeping',
            'process#holder#release-optin-to-consultancy',
            'process#standIn#release-optin-to-consultancy',
            'process#holder#deliver-employee-master-data-to-finstein',
            'process#standIn#deliver-employee-master-data-to-finstein',
            'process#holder#verify-employee-advice-requests',
            'process#standIn#verify-employee-advice-requests',
            'process#holder#change-employee-master-data',
            'process#standIn#change-employee-master-data',
            'process#holder#sign-additions-to-employment-contracts',
            'process#standIn#sign-additions-to-employment-contracts',
            'process#holder#release-employee-for-salary-increase',
            'process#standIn#release-employee-for-salary-increase',
        ];
    }
}
