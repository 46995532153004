import { AppInjector } from '../util/app.injector';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AfterViewInit, NgZone, OnDestroy, OnInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../services/toast.service';
import { CanDeactivateComponent } from './can-deactivate.component';
import { FireLoggingService } from '../services/fire-logging.service';
import { UserService } from '../services/user.service';
import { Observable, of, Subscription } from 'rxjs';
import { ChangeHistoryService } from '../services/change-history.service';
import { ChangeHistoryMappedModel } from '../../shared/models/change-history.model';

@Component({
    template: ''
})
export abstract class BaseComponent extends CanDeactivateComponent implements OnInit, AfterViewInit, OnDestroy {

    isViewMode: boolean;

    protected approvalHistoryId: string;

    // Change history variables
    protected changeHistoryId: string;
    protected changeHistory: ChangeHistoryMappedModel;
    protected changeHistorySubscription: Subscription = new Subscription();

    protected router: Router = AppInjector.get(Router);
    protected ngZone: NgZone = AppInjector.get(NgZone);
    protected changeHistoryService: ChangeHistoryService = AppInjector.get(ChangeHistoryService);
    protected userService: UserService = AppInjector.get(UserService);
    protected spinner: NgxSpinnerService = AppInjector.get(NgxSpinnerService);
    protected notification: ToastService = AppInjector.get(ToastService);
    protected translateService: TranslateService = AppInjector.get(TranslateService);
    protected fireLogginService: FireLoggingService = AppInjector.get(FireLoggingService);

    constructor() {
        super();
        const activatedRoute = AppInjector.get(ActivatedRoute);
        this.changeHistoryId = activatedRoute.snapshot.queryParams.changeHistoryId;
        this.approvalHistoryId = activatedRoute.snapshot.queryParams.approvalHistoryId;
        this.setIsViewMode();
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.changeHistorySubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
    }

    translate(key: string, params?) {
        if (key) {
            return this.translateService.instant(key, params);
        }
    }

    protected setIsViewMode() {
        const activatedRoute = AppInjector.get(ActivatedRoute);
        if (this.userService.loggedUser) {
            this.isViewMode = this.userService.isFinsteinHelper() ||
                activatedRoute.snapshot?.data?.viewMode ||
                activatedRoute.snapshot?.data?.isViewMode ||
                !!activatedRoute.snapshot.queryParams.approvalHistoryId ||
                !!activatedRoute.snapshot.queryParams.changeHistoryId;
        }
    }

    protected navigate(urls: any[], params?: NavigationExtras): void {
        this.ngZone.run(() => this.router.navigate(urls, params)).then();
    }

    protected applyMasks(): void {
        window.setTimeout(() => {
            // $.applyDataMask(':input');
        }, 200);
    }

    canDeactivate(): boolean {
        return true;
    }

    /**
     * This function gets the change history by ID found in the query params. The ID of this change history was got in the constructor of
     * this class (base.component.ts). The first condition of this function is to avoid fetching the change history more than once
     * (its like a kind of "cache").
     *
     * @autor Davi Leal
     * @param params Optional params that can be used in the function `setChangeHistoryData`. This function must be overwritten in the
     * components that wants to show historical data.
     */
    protected fetchChangeHistory(params?: any): Observable<ChangeHistoryMappedModel> {
        if (this.changeHistory) {
            if (this.changeHistory) {
                this.setChangeHistoryData(this.changeHistory, params);
            }
            return of(this.changeHistory);
        } else {
            if (this.changeHistoryId) {
                this.changeHistoryService.getChangeHistoryData(this.changeHistoryId).subscribe((data: ChangeHistoryMappedModel) => {
                    this.changeHistory = data;
                    if (data) {
                        this.setChangeHistoryData(data, params);
                    }
                    of(data);
                });
            } else {
                of(null);
            }
        }
    }

    /**
     * This function must be overwritten by the components that wants to show the historical data on it. And why? Each component has its own
     * way to structure the data to show the historical data. So, because of this, its important that each component implement its own code
     * to organize the data.
     *
     * @autor Davi Leal
     * @param changeHistory The change history fetched by the function `fetchChangeHistory`.
     * @param params Optional params received by the `fetchChangeHistory` sent for the component that extends the base.component.ts
     */
    protected setChangeHistoryData(changeHistory: ChangeHistoryMappedModel, params?: any): void {
    }

}
