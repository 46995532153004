import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, take, tap } from 'rxjs/operators';
import {
    ADVANCED_COLLECTION,
    BENEFITS_SERVICES_COLLECTION,
    CONSULTANCY_PROCESS_COLLECTION,
    DOCUMENTS_COLLECTION,
    EMPLOYEES_COLLECTION,
    SERVICE_RETIREMENT,
    STATUS_ACTIVE
} from '../../app.constants';
import { BaseService } from '../abstractions/base-service';
import { FireLoggingService } from './fire-logging.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ToastService } from './toast.service';
import { compareVersions } from 'src/app/utils/compare-version.util';
import { currencyFormatDE } from 'src/app/utils/currency-formatter';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class EmployeeService {

    constructor(
        private baseService: BaseService<any>,
        private firestore: AngularFirestore,
        private fireLogging: FireLoggingService,
        private storage: AngularFireStorage,
        private notification: ToastService,
        private translateService: TranslateService
    ) {
    }

    public getImportedData(employeeId: string) {
        return this.baseService.getById('general', `${ EMPLOYEES_COLLECTION }/${ employeeId }/${ ADVANCED_COLLECTION }`);
    }

    public getConsultancyProcessById(consultancyProcessId: string, employeeId: string): Observable<any> {
        return this.firestore
            .collection(EMPLOYEES_COLLECTION)
            .doc(employeeId)
            .collection(CONSULTANCY_PROCESS_COLLECTION)
            .doc(consultancyProcessId)
            .get()
            .pipe(
                take(1),
                map(doc => doc.exists ? {
                        id: doc.id,
                        ref: doc.ref,
                        ...doc.data()
                    } : null
                ),
                catchError((error) => {
                    const collection = `${ EMPLOYEES_COLLECTION }/${ employeeId }/${ CONSULTANCY_PROCESS_COLLECTION }/${ consultancyProcessId }`;
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`An error occurred while getting item into collection ${ collection }, details: ${ message || JSON.stringify(error) }`);
                   throw {error};
                })
            );
    }

    public getCOPActiveBenefitsServices(employeeId: string) {
        return this.firestore
            .collection(EMPLOYEES_COLLECTION)
            .doc(employeeId)
            .collection(CONSULTANCY_PROCESS_COLLECTION, ref => ref.orderBy('createdAt', 'desc'))
            .get()
            .pipe(map(doc => doc.docs.length > 0 ? {id: doc.docs[0].id, ref: doc.docs[0].ref, ...doc.docs[0].data()} : null))
            .pipe(concatMap(cop => {
                return this.firestore.doc(cop.ref.path).collection(BENEFITS_SERVICES_COLLECTION).get().pipe(map(res => {
                    return res.docs.filter((doc) => {
                        const data: any = doc.data();
                        if (data.instances) {
                            return data.instances.map(inst => inst.status === STATUS_ACTIVE).length > 0;
                        }
                        return data.status === STATUS_ACTIVE;
                    });
                })).pipe(
                    map(docs => {
                        return docs.map(doc => {
                            const data: any = doc.data();
                            data.id = doc.id;
                            data.ref = doc.ref;
                            return data;
                        });
                    }));
            }))
            .pipe(
                catchError((error) => {
                    const collection = `${EMPLOYEES_COLLECTION}/${employeeId}/${CONSULTANCY_PROCESS_COLLECTION}`;
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`An error occurred while getting item into collection ${ collection } with filter {createdAt: desc}, details: ${ message || JSON.stringify(error) }`);
                    throw {error};
                })
            );
    }

    public getReceiptById(employeeId: string, receiptId: string) {
        return this.firestore
            .collection(EMPLOYEES_COLLECTION)
            .doc(employeeId)
            .collection(DOCUMENTS_COLLECTION)
            .doc(receiptId)
            .get()
            .pipe(
                map(res => {
                    const data: any = res.data();
                    data.id = res.id;
                    return data;
                }),
                concatMap((item: any) => {
                    if (item) {
                        let downloadUrl;
                        if (item.fileReference) {
                            downloadUrl = this.storage
                                .ref(item.fileReference)
                                .getDownloadURL();
                        } else {
                            downloadUrl = of(item.url);
                        }
                        return downloadUrl.pipe(
                            take(1),
                            catchError((err) => {
                                this.notification.error(err.message);
                                return of('');
                            }),
                            map((url: string) => {
                                item.url = url;
                                return item;
                            })
                        );
                    }
                }),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ EMPLOYEES_COLLECTION }/${ DOCUMENTS_COLLECTION }/${ receiptId }`;
                    this.fireLogging.sendErrorLog(`[getReceiptById(employee.service.ts)] An error occurred while getting items from collection ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    getEmployeeActiveServicesAndCop(consultancyProcessId: string, employeeId: string): Observable<{ consultancyProcess: any, services: any}> {
        return this.getConsultancyProcessById(consultancyProcessId, employeeId)
            .pipe(
                map((consultancyProcess) => {
                    const summary = consultancyProcess.summary;
                    const servicesGroupOne =
                        summary.servicesGroupOneResult || [];
                    const servicesGroupTwo =
                        summary.servicesGroupTwoResult || [];
                    const services = [
                        ...servicesGroupOne,
                        ...servicesGroupTwo,
                    ].filter((item) => {
                        if (item.instances) {
                            item.instances = item.instances.filter(
                                (instance) =>
                                    instance.status === STATUS_ACTIVE
                            );
                            return item.instances.length > 0;
                        }
                        return item.status === STATUS_ACTIVE;
                    });

                    return { consultancyProcess, services };
                }),
                catchError((error) => {
                    this.fireLogging.sendErrorLog(
                        `An error occurred while fetching the employee services, details: ${JSON.stringify(
                            error
                        )}`
                    );

                    throw { error };
                })
            );
    }

    /**
     * Sets the salary texts based on the values of the consultancy process summary.
     * The salary texts are used for localization and display purposes.
     */
    setSalaryTexts(
        consultancyProcess: any,
        retirement: any,
        contractDate: string,
        exportableCode: string
    ) {
        const salaryTexts = {
            label: '',
            text1: '',
            text2: '',
        };
        const copSummary = consultancyProcess?.summary;

        // Conditional variables
        // const retirement = services.find(
        //     (service) => service.id === SERVICE_RETIREMENT
        // );
        const depositBAV = retirement?.depositBAV || 0;
        const salaryConversion = copSummary?.salaryConversion || 0;
        const nominalSalaryIncrease = copSummary?.nominalSalaryIncrease || 0;
        const servicesNetValueSum = copSummary?.servicesNetValueSum || 0;
        const monthlyRetirementPayment =
            copSummary?.monthlyRetirementPayment || 0;
        const depositEmployeeBAV = retirement?.depositEmployeeBav || 0;
        const usedSalaryIncrease = retirement?.usedSalaryIncrease || 0;
        const notUsedSalaryIncrease = copSummary?.notUsedSalaryIncrease || 0;
        const salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV =
            salaryConversion - notUsedSalaryIncrease - depositEmployeeBAV;

        // Calculated variables used in i18n
        const salaryConversionMinusDepositEmployeeBAV =
            salaryConversion - depositEmployeeBAV;

        const notUsedSalaryIncreasePlusDepositBAV =
            compareVersions(copSummary.version, '1.0.11') < 0
                ? notUsedSalaryIncrease + depositBAV
                : notUsedSalaryIncrease + depositEmployeeBAV;

        if (salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV == 0 && usedSalaryIncrease > 0) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-remains-the-same';
            salaryTexts.text2 = '';
        } else if (salaryConversion > 0 && nominalSalaryIncrease == 0) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-conversion.text-1';
            salaryTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-conversion.text-2';
        } else if (salaryConversion == 0 && servicesNetValueSum > 0) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-increase.text-1';
        } else if (
            retirement?.depositEmployeeBav &&
            salaryConversion == depositEmployeeBAV &&
            nominalSalaryIncrease > 0 &&
            servicesNetValueSum > salaryConversion
        ) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-increase-and-conversion.text-1';
        } else if (
            retirement?.depositEmployeeBav &&
            salaryConversion == depositEmployeeBAV &&
            nominalSalaryIncrease > 0 &&
            servicesNetValueSum == salaryConversion
        ) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.salary-increase-and-conversion-with-only-bav.text-1';
        } else if (
            retirement?.type == 'SALARY_CONVERSION' &&
            nominalSalaryIncrease > 0 &&
            salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV <
                0
        ) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.retirement-by-conversion.text-1';
            salaryTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.retirement-by-conversion.text-2';
        } else if (
            retirement?.type == 'SALARY_CONVERSION' &&
            nominalSalaryIncrease > 0 &&
            salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV >
                0
        ) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.retirement-by-conversion-positive-optimization.text-1';
            salaryTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.retirement-by-conversion-positive-optimization.text-2';
        } else if (
            retirement?.type == 'SALARY_INCREASE' &&
            salaryConversion > 0
        ) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.bav-financed-by-company.text-1';
            salaryTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.bav-financed-by-company.text-2';
        } else if (monthlyRetirementPayment == 0 && nominalSalaryIncrease > 0) {
            salaryTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.no-bav.text-1';
            salaryTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.no-bav.text-2';
        }

        salaryTexts.text1 = this.translateService.instant(salaryTexts.text1, {
            contractDate: contractDate,
            notUsedSalaryIncreasePlusDepositEmployeeBAV: currencyFormatDE(
                notUsedSalaryIncreasePlusDepositBAV
            ),
            salaryConversionMinusDepositEmployeeBAV: currencyFormatDE(
                salaryConversionMinusDepositEmployeeBAV
            ),
            notUsedSalaryIncrease: currencyFormatDE(notUsedSalaryIncrease),
            salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV:
                currencyFormatDE(
                    Math.abs(
                        salaryConversionMinusNotUsedSalaryIncreaseMinusDepositEmployeeBAV
                    )
                ),
        });
        if (salaryTexts.text2) {
            salaryTexts.text2 = this.translateService.instant(salaryTexts.text2, {
                key: exportableCode || '',
            });
        }
        salaryTexts.label = this.translateService.instant(
            'employees.contracts.confirm-contract-inserted-into-payroll-system.salary.label'
        );

        return salaryTexts;
    }

    /**
     * Sets the BavServiceTexts based on the values of the ConsultancyProcess summary and other variables.
     */
    setBavServiceTexts(
        consultancyProcess: any,
        retirement: any,
        contractDate: string,
        payrollSystem: string
    ) {
        const bavServiceTexts = {
            label: '',
            text1: '',
            text2: '',
        };
        const copSummary = consultancyProcess?.summary;

        // Conditional variables
        const salaryConversion = copSummary?.salaryConversion || 0;
        const nominalSalaryIncrease = copSummary?.nominalSalaryIncrease || 0;
        const servicesNetValueSum = copSummary?.servicesNetValueSum || 0;

        if (retirement && retirement?.depositBAV > 0) {
            const depositEmployeeBAV = retirement?.depositEmployeeBav || 0;
            const usedSalaryIncrease = retirement?.usedSalaryIncrease || 0;
            const employeeCompanyRate = retirement?.employeeCompanyRate ?? 15;

            if (usedSalaryIncrease > 0) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-remains-the-same';
            } else if (salaryConversion > 0 && nominalSalaryIncrease === 0) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-conversion.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-conversion.text-2';
            } else if (salaryConversion === 0 && servicesNetValueSum > 0) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase.text-2';
            } else if (
                retirement?.depositEmployeeBav &&
                salaryConversion == depositEmployeeBAV &&
                nominalSalaryIncrease > 0 &&
                servicesNetValueSum > salaryConversion
            ) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase-and-conversion.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase-and-conversion.text-2';
            } else if (
                retirement?.depositEmployeeBav &&
                salaryConversion == depositEmployeeBAV &&
                nominalSalaryIncrease > 0 &&
                servicesNetValueSum == salaryConversion
            ) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase-and-conversion-with-only-bav.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.salary-increase-and-conversion-with-only-bav.text-2';
            } else if (
                retirement?.type == 'SALARY_CONVERSION' &&
                nominalSalaryIncrease > 0
            ) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.retirement-by-conversion.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.retirement-by-conversion.text-2';
            } else if (
                retirement?.type == 'SALARY_INCREASE' &&
                salaryConversion > 0
            ) {
                bavServiceTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.bav-financed-by-company.text-1';
                bavServiceTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.bav-financed-by-company.text-2';
            }

            // Calculated variables used in i18n
            const depositBAV = retirement?.depositBAV || 0;
            const employerContributionRate =
                consultancyProcess?.netBenefitsDistribution
                    ?.employeeCompanyRate;
            const depositCompanyBAV = retirement?.depositCompanyBav || 0;

            bavServiceTexts.text1 = this.translateService.instant(
                bavServiceTexts.text1,
                {
                    employerContributionRate,
                    contractDate: contractDate,
                    payrollSystem: this.translateService.instant(`wage-accounting-systems.${payrollSystem?.toLowerCase()}`),
                    depositBAV: currencyFormatDE(depositBAV),
                    depositEmployeeBAV: currencyFormatDE(depositEmployeeBAV),
                    depositCompanyBAV: currencyFormatDE(depositCompanyBAV),
                    employeeCompanyRate
                }
            );

            if (bavServiceTexts.text2) {
                bavServiceTexts.text2 = this.translateService.instant(
                    bavServiceTexts.text2,
                    {
                        depositBAV: currencyFormatDE(depositBAV),
                        employerContributionRate: employerContributionRate,
                    }
                );
            }
            bavServiceTexts.label = this.translateService.instant(
                'employees.contracts.confirm-contract-inserted-into-payroll-system.bav-service.label'
            );
        }

        return bavServiceTexts;
    }

    /**
     * Sets the used gross for components based on the values in the consultancy process summary.
     * Updates the bavServiceTexts object with the appropriate text based on the conditions.
     * Calculates and formats various variables used in internationalization (i18n).
     */
    setUsedGrossForComponentsTexts(
        consultancyProcess: any,
        retirement: any,
        contractDate: string,
        payrollSystem: string
    ) {
        const usedGrossForComponentsTexts = {
            label: '',
            text1: '',
            text2: '',
        };
        const copSummary = consultancyProcess?.summary;

        // Conditional variables
        const salaryConversion = copSummary?.salaryConversion || 0;
        const nominalSalaryIncrease = copSummary?.nominalSalaryIncrease || 0;
        const servicesNetValueSum = copSummary?.servicesNetValueSum || 0;
        const monthlyRetirementPayment =
            copSummary?.monthlyRetirementPayment || 0;
        const depositEmployeeBAV = retirement?.depositEmployeeBav || 0;
        if (retirement) {
            if (salaryConversion > 0 && nominalSalaryIncrease === 0) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-conversion.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-conversion.text-2';
            } else if (salaryConversion === 0 && servicesNetValueSum > 0) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase.text-1';
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase.text-2';
            } else if (
                retirement?.depositEmployeeBav &&
                salaryConversion === depositEmployeeBAV &&
                nominalSalaryIncrease > 0 &&
                servicesNetValueSum > salaryConversion
            ) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase-and-conversion.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase-and-conversion.text-2';
            } else if (
                retirement?.depositEmployeeBav &&
                salaryConversion === depositEmployeeBAV &&
                nominalSalaryIncrease > 0 &&
                servicesNetValueSum === salaryConversion
            ) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase-and-conversion-with-only-bav.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.salary-increase-and-conversion-with-only-bav.text-2';
            } else if (
                retirement?.type === 'SALARY_CONVERSION' &&
                nominalSalaryIncrease > 0
            ) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.retirement-by-conversion.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.retirement-by-conversion.text-2';
            } else if (
                retirement?.type === 'SALARY_INCREASE' &&
                salaryConversion > 0
            ) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.bav-financed-by-company.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.bav-financed-by-company.text-2';
            } else if (
                monthlyRetirementPayment === 0 &&
                nominalSalaryIncrease > 0
            ) {
                usedGrossForComponentsTexts.text1 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.no-bav.text-1';
                usedGrossForComponentsTexts.text2 =
                    'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.no-bav.text-2';
            }
        } else {
            usedGrossForComponentsTexts.text1 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.no-bav.text-1';
            usedGrossForComponentsTexts.text2 =
                'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.no-bav.text-2';
        }

        // Calculated variables used in i18n
        const salaryConversionMinusDepositEmployeeBAV =
            salaryConversion - depositEmployeeBAV;
        const depositEmployeePlusSalaryConversionMinusDepositEmployeeBAV =
            depositEmployeeBAV + salaryConversionMinusDepositEmployeeBAV;
        const depositBAV = retirement?.depositBAV || 0;
        const servicesNetValueSumMinusDepositBAV =
            servicesNetValueSum - depositBAV;
        const depositBAVPlusServicesNetValueSumMinusDepositBAV =
            depositBAV + servicesNetValueSumMinusDepositBAV;
        const servicesNetValueSumMinusDepositEmployeeBAV =
            servicesNetValueSum - depositEmployeeBAV;
        const depositEmployeeBAVPlusServicesNetValueSumMinusDepositEmployeeBAV =
            depositEmployeeBAV + servicesNetValueSumMinusDepositEmployeeBAV;

        usedGrossForComponentsTexts.text1 = this.translateService.instant(
            usedGrossForComponentsTexts.text1,
            {
                contractDate: contractDate,
                nominalSalaryIncrease: currencyFormatDE(nominalSalaryIncrease),
                payrollSystem: this.translateService.instant(`wage-accounting-systems.${payrollSystem?.toLowerCase()}`),
                salaryConversionMinusDepositEmployeeBAV: currencyFormatDE(
                    salaryConversionMinusDepositEmployeeBAV
                ),
                depositEmployeePlusSalaryConversionMinusDepositEmployeeBAV:
                    currencyFormatDE(
                        depositEmployeePlusSalaryConversionMinusDepositEmployeeBAV
                    ),
                nominalSalaryIncreasePlusSalaryConversion: currencyFormatDE(
                    nominalSalaryIncrease + salaryConversion
                ),
                depositBAV: currencyFormatDE(depositBAV),
                depositEmployeeBAV: currencyFormatDE(depositEmployeeBAV),
                servicesNetValueSum: currencyFormatDE(servicesNetValueSum),
                servicesNetValueSumMinusDepositBAV: currencyFormatDE(
                    servicesNetValueSumMinusDepositBAV
                ),
                depositBAVPlusServicesNetValueSumMinusDepositBAV:
                    currencyFormatDE(
                        depositBAVPlusServicesNetValueSumMinusDepositBAV
                    ),
                servicesNetValueSumMinusDepositEmployeeBAV: currencyFormatDE(
                    servicesNetValueSumMinusDepositEmployeeBAV
                ),
                depositEmployeeBAVPlusServicesNetValueSumMinusDepositEmployeeBAV:
                    currencyFormatDE(
                        depositEmployeeBAVPlusServicesNetValueSumMinusDepositEmployeeBAV
                    ),
            }
        );

        if (usedGrossForComponentsTexts.text2) {
            usedGrossForComponentsTexts.text2 = this.translateService.instant(
                usedGrossForComponentsTexts.text2,
                {
                    nominalSalaryIncrease: currencyFormatDE(nominalSalaryIncrease),
                    nominalSalaryIncreasePlusSalaryConversion: currencyFormatDE(
                        nominalSalaryIncrease + salaryConversion
                    ),
                    depositEmployeeBAV: currencyFormatDE(depositEmployeeBAV),
                    servicesNetValueSum: currencyFormatDE(servicesNetValueSum),
                    depositBAVPlusServicesNetValueSumMinusDepositBAV:
                        currencyFormatDE(
                            depositBAVPlusServicesNetValueSumMinusDepositBAV
                        ),
                    depositEmployeePlusSalaryConversionMinusDepositEmployeeBAV:
                        currencyFormatDE(
                            depositEmployeePlusSalaryConversionMinusDepositEmployeeBAV
                        ),
                    depositEmployeeBAVPlusServicesNetValueSumMinusDepositEmployeeBAV:
                        currencyFormatDE(
                            depositEmployeeBAVPlusServicesNetValueSumMinusDepositEmployeeBAV
                        ),
                }
            );
        }
        usedGrossForComponentsTexts.label = this.translateService.instant(
            'employees.contracts.confirm-contract-inserted-into-payroll-system.used-gross-for-components.label'
        );

        return usedGrossForComponentsTexts;
    }
}
