import {Component, OnInit} from '@angular/core';
import {BaseComponent} from 'src/app/core/abstractions/base.component';
import {SideMenuService} from '../../services/side-menu.service';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-administration-side-menu',
    templateUrl: './administration-side-menu.component.html',
    styleUrls: ['./administration-side-menu.component.scss']
})
export class AdministrationSideMenuComponent extends BaseComponent implements OnInit {

    permissions = {
        users: ['finstein-user-read'],
        companyLeads: ['finstein-company-lead-read', 'finstein-company-lead-read-external'],
        roles: ['finstein-role-read']
    };

    constructor(private sideMenu: SideMenuService) {
        super();
    }

    get loggedUser() {
        return this.userService.loggedUser;
    }

    ngOnInit() {
        super.ngOnInit();
    }

    isActiveRoute(routeName: string, regex?: string): boolean {
        if (regex) {
            return new RegExp(regex).test(this.router.url);
        } else {
            if (routeName.includes('**')) {
                const split = routeName.split('/**', 1);
                return this.router.url.indexOf(split[0]) > -1;
            }
            return this.router.url === routeName;
        }
    }

    toggleSidebar() {
        this.sideMenu.toggleSidebar();
    }

    activeSidebar() {
        this.sideMenu.toggleSidebarPin();
    }

    isCollapsed() {
        return this.sideMenu.opened;
    }

    isFinsteinHelper() {
        return this.userService.isFinsteinHelper();
    }

    isAllowedEnv() {
        return environment.env === 'test' || environment.env === 'dev';
    }
}
